import {combineReducers} from 'redux'
import authReducer from '../reducers/authReducer'
import servicesReducer from './servicesReducer'
import iconReducer from './iconReducer'
import configurationReducer from './configurationReducer'
import userReducer from './userReducer'
import providerReducer from './providerReducer'
import faqReducer from './faqReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  configurations: configurationReducer,
  faq: faqReducer,
  icon: iconReducer,
  service: servicesReducer,
  users: userReducer,
  providers: providerReducer,
})

export default rootReducer
