import React, { useEffect } from 'react'
import { MDBDataTableV5 } from 'mdbreact'
import { UsersListFilter } from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import { useParams } from 'react-router-dom'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'
import moment from 'moment'
import { useMemberAppointment } from '../hooks/useMemberQuery'
import { checkAppointmentStatus } from '../../../../util'

type AppointmentRow = {
  no: number
  provider: string
  date: any
  startingTime: string
  endingTime: string
  duration: string
  fee: string
  paymentStatus: string
  totalPayment: string
  status: string
  id: string
  tag: string
}

const Appointment = () => {
  const { Id } = useParams()

  const initTable = {
    columns: [
      {
        label: 'No',
        field: 'no',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Provider',
        field: 'provider',
        width: 270,
      },
      {
        label: 'Date',
        field: 'date',
        width: 200,
      },
      {
        label: 'Time',
        field: 'time',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Status',
        field: 'status',
        width: 150,
      },
      {
        label: 'Duration',
        field: 'duration',
        width: 150,
      },
      {
        label: 'Fee',
        field: 'fee',
        width: 150,
      },
      {
        label: 'Payment',
        field: 'paymentStatus',
        width: 150,
      },
      {
        label: 'Total',
        field: 'totalPayment',
        width: 150,
      },
    ],
    rows: [],
  }

  const dateToString = (date: Date | undefined) => {
    if (date) {
      return moment(date).format('MM/DD/yyyy')
    } else {
      return ''
    }
  }

  const rowItem = (item: AppointmentRow) => {
    return {
      no: item.no,
      provider: item.provider,
      date: [<span className='badge badge-secondary'>{dateToString(item.date)}</span>],
      time: [
        <span className='badge badge-secondary'>{item.startingTime}</span>,
        ' - ',
        <span className='badge badge-secondary'>{item.endingTime}</span>,
      ],
      status: [
        <span
          className={`badge ${item.tag}`}
        >
          {item.status}
        </span>,
      ],
      duration: [<span className='badge badge-secondary'>{item.duration}</span>],
      fee: item.fee ? <span>${item.fee}</span> : null,
      paymentStatus: [
        <span className={`text-uppercase badge ${item.paymentStatus ? 'text-success' : 'text-danger'}`}>
          {item.paymentStatus ? 'Paid' : 'Unpaid'}
        </span>,
      ],
      totalPayment: '$' + item.totalPayment,
    }
  }

  const listSelector = (res: any) => {

    var rows: never[] = []

    res?.forEach((element: any, index: number) => {
      const { statusToShow, tag }: any = checkAppointmentStatus(
        element.status,
        element.isPaid,
        element.isReservationFee
      )
      const item: AppointmentRow = {
        no: index + 1,
        provider: ` ${element.provider.firstName} ${element.provider.lastName}`,
        date: element.appointmentDate,
        startingTime: element.startTime,
        endingTime: element.endTime,
        duration: `${element.totalDuration} ${'minutes'}`,
        fee: element.fee,
        paymentStatus: element.isPaid,
        totalPayment: element.totalPrice,
        status: statusToShow,
        id: element.id,
        tag: tag
      }
      rows.push(rowItem(item) as never)
    })

    var table = initTable
    table.rows = rows
    return table
  }

  const { isLoading, data }: any = useMemberAppointment(Id as string, listSelector)

  return (
    <>
      <div style={{ minHeight: '50vh' }} className={`card mb-5 mb-xl-10`}>
        <div className='row'>
          <div className='col-12 text-end'>
            <UsersListFilter />
          </div>
        </div>

        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
          }}
          className='card mb-5 mb-xl-10 p-5'
        >
          {isLoading ? (
            <TableLoading />
          ) : (
            <MDBDataTableV5 hover order={['age', 'desc']} data={data} />
          )}
        </div>
      </div>
    </>
  )
}

export default Appointment
