import React from 'react'
import {useAppDispatch} from '../../../store'
import {addRemoveEntryInArrayState, updateArrayState} from '../../../reducers/configurationReducer'

const ArrayConfig = ({state}: any) => {
  const dispatch = useAppDispatch()

  // state = {
  //   words: ['Hello', 'Hi', 'How are you', 'Cool'],
  // }

  const handleChange = (e: any) => {
    dispatch(updateArrayState(e))
  }

  const handleAdd = () => {
    const updatedValue = [...state.value, '']
    dispatch(addRemoveEntryInArrayState(updatedValue))
  }

  const handleRemove = (idx: number) => {
    const updatedValue = [...state.value]
    updatedValue.splice(idx, 1)
    dispatch(addRemoveEntryInArrayState(updatedValue))
  }

  return (
    <div className='row'>
      {state?.value?.map((data: string, idx: number) => (
        <div className='col-lg-6 my-2' key={idx}>
          <div className='input-group'>
            <input
              type='text'
              onChange={(e) => {
                handleChange({
                  value: e.target.value,
                  idx: idx,
                })
              }}
              value={data}
              className='form-control form-control-solid py-2'
            />
            <div
              className='border border-danger bg-danger text-light p-2 pointer mx-2 rounded-1'
              id='basic-addon1'
              onClick={() => handleRemove(idx)}
            >
              x
            </div>
          </div>
        </div>
      ))}
      <div className='col-12 text-end'>
        <button className='btn btn-primary' onClick={handleAdd}>
          Add
        </button>
      </div>
    </div>
  )
}

export default ArrayConfig
