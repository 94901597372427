/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import MediaCard from './components/MediaCard'
import {getExplores} from '../../../api/endpoints/explore'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {}

const Explore: FC<Props> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [mediaList, setMediaList] = React.useState<any>([])
  const [pagination, setPagination] = React.useState<any>()
  const [search, setSearch] = React.useState<any>('')

  React.useEffect(() => {
    getAllMedia()
    return () => {
      setMediaList([])
    }
  }, [])

  const getAllMedia = async () => {
    const res: any = await getExplores(page)

    const {data, success, message} = res.data
    // console.log("🚀 ~ file: Explore.tsx:28 ~ getAllMedia ~ data", data)
    // console.log(data, success)
    // console.log('res', res)

    if (success) {
      const {medias, ...rest} = data
      setPagination(rest)
      mediaList.push(...medias)
      if (data?.nextPageUrl) {
        setPage((prev) => prev + 1)
      }
    }
  }

  const handleChange = (e: any) => {
    if (e.target.value === '') {
      setSearch(null)
    } else {
      // setSearching(true);
      setSearch(e.target.value.toUpperCase())
    }
    // console.log(search);
  }

  return (
    <>
      <div className='row mb-5 mt-n5 pt-n5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Feed</h2>
        </div>
        {/* <div className="col-6 align-self-center text-end">
                    <AppointmentlistFilter />
                </div> */}
      </div>
      <div className='card mb-5 mb-xl-10 pt-4'>
        {/* search section */}
        {/* <div className='d-flex align-items-center justify-content-end my-3 px-4'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute me-5'
          />
          <input
            // onChange={handleChange}
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search media'
          />
        </div> */}
        {mediaList.length === 0 ? (
          <div
            style={{minHeight: '50vh'}}
            className={`card mb-5 mb-xl-10 py-8 d-flex justify-content-center`}
          >
            <div className='d-flex justify-content-center my-5'>
              <div className='spinner-grow text-dark' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={mediaList?.length} //This is important field to render the next data
            next={() => getAllMedia()}
            hasMore={pagination?.nextPageUrl}
            loader={
              <div className='d-flex justify-content-center my-5'>
                <div className='spinner-grow text-dark' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            }
            endMessage={
              <p style={{textAlign: 'center'}}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className='row w-100 m-auto align-items-center mt-4'>
              {mediaList?.map((data: any, idx: number) => (
                <MediaCard key={idx} data={data} />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

export {Explore}
