import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchIconAction } from '../../../reducers/iconReducer'
import { serviceOneSelector, statusSelector } from '../../../reducers/servicesReducer'
import { AppDispatch } from '../../../store'
import ServiceCard from '../components/ServiceCard'
import ServicesLayout from '../ServicesLayout'
import ServiceLoading from '../components/ServiceLoading'

const ServicesOne = () => {
  const serviceData = useSelector(serviceOneSelector)
  const status = useSelector(statusSelector)
  const dispatch = useDispatch<AppDispatch>()

  const [sort, setSort] = useState(0);

  useEffect(() => {
    dispatch(fetchIconAction())
  }, [])

  const toggleSort = () => {
    setSort((prevSort) => (prevSort + 1) % 3);
  };

  const sortData = (data: any[], sortType: string) => {
    return data.sort(
      (a, b) => a?.name.localeCompare(b?.name) * (sortType === "asc" ? 1 : -1)
    );
  };

  const SortedServiceData = useMemo(() => {
    let data = serviceData

    switch (sort) {
      case 1:
        data = sortData([...data], "asc");
        break;
      case 2:
        data = sortData([...data], "desc");
        break;
      default:
        data = data;
    }
    return data;
  }, [sort, serviceData]);

  return (
    <ServicesLayout>
      <div className='d-flex justify-content-end mt-n8 mb-3 dropdown'>
        <div
          onClick={toggleSort}
          className={`btn btn-sm btn-icon btn-color-white border-0 me-3 ${sort ? "bg-primary" : "bg-secondary"}`}
        >
          <img
            style={{ filter: "brightness(200 %) saturate(0%)" }}
            src={`${sort == 2 ? '/media/ztoa.png' : '/media/atoz.png'}`}
            alt='icon'
            className='my-1'
          />
        </div>
        <div style={{ cursor: 'auto' }}
          className='btn btn-sm btn-icon btn-color-white border-0 me-n3 bg-primary'
        >
          {serviceData?.length}
        </div>
      </div>
      <div className='row mt-5'>
        {status === 'loading' ? (
          <ServiceLoading />
        ) : (
          SortedServiceData?.map((ser: any, idx: number) => (
            <ServiceCard key={idx} data={ser} />
          ))
        )
        }
      </div>
    </ServicesLayout>
  )
}

export default ServicesOne
