import {KTSVG} from '../../../_metronic/helpers'

export enum ArrowDirections {
  up = 'arr066.svg',
  down = 'arr065.svg',
}

type SquareStatePropType = {
  title: string
  value: string
  direction?: ArrowDirections
  isLoading?: boolean
}

const SquareState = ({title, value, direction, isLoading}: SquareStatePropType) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
      <div className='d-flex align-items-center'>
        {direction && (
          <KTSVG
            path={`/media/icons/duotune/arrows/${direction}`}
            className={`svg-icon-3 svg-icon-${
              ArrowDirections.down === direction ? 'danger' : 'success'
            } me-2`}
          />
        )}
        <div className='fs-2 fw-bolder'>{value}</div>
      </div>
      <div className='fw-bold fs-6 text-gray-400'>{title}</div>
    </div>
  )
}

export default SquareState
