import { useQuery } from "react-query"
import { GetAppointmentsListRequest, fetchSingleAppointmentRequest } from "../../../../api/endpoints/appointment"

export const useAppointmentList = (selectorFun: any) => {
    return useQuery({
        queryKey: 'appointmentList',
        queryFn: GetAppointmentsListRequest,
        select: selectorFun,
        cacheTime: 0,
    })
}

export const useAppointmentDetail = (id: string) => {
    return useQuery({
        queryKey: 'appointmentDetail',
        queryFn: () => fetchSingleAppointmentRequest(id),
        cacheTime: 0,
    });
};
