import React from 'react'
import { GoVerified } from 'react-icons/go'

type verifiedProps = {
  // text?: string
  isVerified?: boolean
}

const Verified = ({ isVerified }: verifiedProps) => {
  return (
    <span>
      <GoVerified className='me-2 mb-1' size={'1.2rem'} color={isVerified ? '#50cd89' : '#EBEBE4'} />
      {/* {text} */}
    </span>
  )
}

export default Verified
