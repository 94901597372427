import client from '../client/client'

const getConfigurations = async () => await client.get('/api/v1/admin/configurations')
const getConfigurationByKeyRequest = async (key: string) =>
  await client.get(`/api/v1/configurations/get-by-key/${key}`)

const updateConfigurationsRequest = async (formData: any) => {
  // console.log(JSON.stringify(formData.data))

  return client.put(`/api/v1/admin/configurations/${formData.id}`, formData.data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export {getConfigurations, updateConfigurationsRequest, getConfigurationByKeyRequest}
