import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SHOW_SUCCESS} from '../../../util'
import {RootState} from '../../store'
import { addIconsRequest, deleteIconsRequest, getIconsRequest } from '../../../api/endpoints/icon'

// This type describes the error object structure:
export type FetchTodosError = {
  message: string
}

export interface iconState {
  list: Array<any>
  status: string
  error: string | null

  // currentUser: CurrentUser
}

export interface iconPayload {
  data: any
  success: boolean | null
  code: number
}

export const initialState: iconState = {
  list: [],
  status: 'idle',
  error: null,
}

export const fetchIconAction = createAsyncThunk('icon/fetch', async () => {
  try {
    const res: any = await getIconsRequest()
    const {data, success, message} = res.data

    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    console.error(error)
    return error
  }
})

export const deleteIconAction = createAsyncThunk('icon/delete', async (id: number) => {
  try {
    const res: any = await deleteIconsRequest(id)
    const {data, success, message} = res.data

    if (success) {
      SHOW_SUCCESS(success, 'icon deleted successfully !')
    }

    return {
      data: id,
      success,
      code: 201,
    }
  } catch (error) {
    console.error(error)
    return error
  }
})

export const addIconAction = createAsyncThunk('icon/create', async (formData: any) => {
  try {
    const res: any = await addIconsRequest(formData.data)
    const {data, success} = res.data
    if (success) {
      console.log('added ', success)

      formData.handleClose()
      SHOW_SUCCESS(success, 'icon added successfully !')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

const iconSlice = createSlice({
  name: 'icon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIconAction.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(fetchIconAction.fulfilled, (state, {payload}) => {
      const {data, success, code}: any = payload
      if (success && code === 200) {
        state.status = 'success'
        state.list = data
      } else {
        state.status = 'fail'
        state.error = null
      }
    })
    // When a server responses with an error:
    builder.addCase(fetchIconAction.rejected, (state, {payload}) => {
      state.error = payload as string
      state.status = 'idle'
    })

    // delete
    builder.addCase(deleteIconAction.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })

    builder.addCase(deleteIconAction.fulfilled, (state, {payload}) => {
      const {data, success, code}: any = payload
      if (success && code === 201) {
        state.status = 'success'
        state.list = state.list.filter((icon: any) => icon?.id != data)
      } else {
        state.status = 'fail'
        state.error = null
      }
    })
    // When a server responses with an error:
    builder.addCase(deleteIconAction.rejected, (state, {payload}) => {
      state.error = payload as string
      state.status = 'idle'
    })

    builder.addCase(addIconAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(addIconAction.fulfilled, (state, action) => {
      const {success, code, data}: any = action.payload
      if (success && code === 200) {
        state.status = 'success'
        state.list.push(data)
      } else {
        state.status = 'fail'
        state.error = null
      }
    })
    builder.addCase(addIconAction.rejected, (state, action) => {
      state.error = action.payload as string
      state.status = 'idle'
    })
  },
})

export default iconSlice.reducer

export const iconListSelector = (state: RootState) => state.icon.list
export const statusSelector = (state: RootState) => state.icon.status
// export const serviceTwoSelector = (state: RootState) => state.service.services_level2
// export const serviceThreeSelector = (state: RootState) => state.service.services_level3
// export const getIsLoggedIn = (state: RootState) => state?.auth.isLoggedIn
