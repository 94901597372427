import React from 'react'
import { useParams } from 'react-router-dom'
import { UsersListFilter } from '../../../modules/apps/user-management/users-list/components/header/UsersListFilter'
import { MembersTeamCard } from '../../../../_metronic/partials/content/cards/MembersTeamCard'
import { useMemberConnection } from '../hooks/useMemberQuery'

type actionProps = {
  loading: boolean
  error: string | null
  message: string
}

const Connection = () => {
  const { Id } = useParams()

  const { isLoading, data } = useMemberConnection(Id as string)

  return (
    <>
      <div style={{ minHeight: '60vh' }} className={`card mb-5 mb-xl-10 pt-8 ${data?.length === 0 && "d-flex justify-content-center"}`}>
        {isLoading ? (
          <div className='d-flex justify-content-center my-5'>
            <div className='spinner-grow text-dark' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          data.length === 0 ? (
            <div className='text-center mt-4 mb-5'>
              <h3 className='text-muted'>No matching records found :(</h3>
            </div>
          ) : (
            <div className='row g-6 g-xl-9 p-3'>
              {data?.map((items: any, idx: number) => (
                <div key={idx} className='col-md-3 col-xxl-4'>
                  <MembersTeamCard
                    avatar={
                      items?.connection?.profileImage === null
                        ? '/media/avatars/blank.png'
                        : items?.connection?.profileImage
                    }
                    name={items?.connection?.firstName}
                    Category=''
                    SubCategory=''
                    navigates={
                      items?.connectionRelation === "provider"
                        ? `/provider/${items?.connection?.id}/profile`
                        : `/member/${items?.connection?.id}/profile`
                    }
                    isVerified={items.connection?.isMobileVerified ? true : false}
                  />
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </>
  )
}

export default Connection
