import React, {useEffect, useState} from 'react'
// import Button from '@mui/material/Button';
import {MDBDataTableV5} from 'mdbreact'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'
import {ViewIcon} from '../../../modules/common/TableIcons'
import {getConfigurations} from '../../../../api/endpoints/configuration'
import {snake_to_title} from '../../../../util/helper'
import {
  configurationStatusSelector,
  configurationsSelector,
  getConfigurationsAction,
} from '../../../reducers/configurationReducer'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../../store'

type ProviderRow = {
  configKey: string
  id: string
}

export default function Config() {
  const initTable = {
    columns: [
      {
        label: 'Configuration',
        field: 'configKey',
        sort: 'disabled',
        width: 270,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: [],
  }
  const dispatch = useDispatch<AppDispatch>()
  const [dataTable, setDataTable] = React.useState()
  const [config, setConfig] = React.useState<any>([])

  const rowItem = (item: ProviderRow, data: any) => {
    return {
      configKey: item.configKey,
      action: [<ViewIcon path={`/configuration/view`} data={data} active={true} />],
    }
  }

  const configurations = useSelector(configurationsSelector)
  const status = useSelector(configurationStatusSelector)

  useEffect(() => {
    // getConfig()
    dispatch(getConfigurationsAction())
  }, [])

  useEffect(() => {
    var rows: never[] = []

    configurations?.forEach((element: any) => {
      const item: ProviderRow = {
        configKey: snake_to_title(element?.key),
        id: element?.id,
      }
      rows.push(rowItem(item, element) as never)
    })

    var table = initTable
    table.rows = rows
    setDataTable(table as any)
  }, [configurations])

  // const getConfig = async () => {
  //     const res: any = await getConfigurations()
  //     const { data, success, message } = res.data

  //     var rows: never[] = []

  //     if (success) {
  //         setConfig({ data, success, message })
  //     }

  //     if (success) {
  //         data.forEach((element: any) => {
  //             const item: ProviderRow = {
  //                 configKey: snake_to_title(element?.key),
  //                 id: element?.id,
  //             }
  //             rows.push(rowItem(item, element) as never)
  //         })
  //     }

  //     var table = initTable
  //     table.rows = rows
  //     setDatable(table as any)
  // }

  return (
    <>
      <div className='row mb-5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Configurations</h2>
        </div>
        <div className='col-6 align-self-center text-end'>
          {/* <button type="button" className="btn btn-primary">
              Create
            </button> */}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
        className='card mb-5 mb-xl-10 p-5'
      >
        {status === 'success' ? (
          <MDBDataTableV5 hover order={['age', 'desc']} data={dataTable} />
        ) : status === 'failed' ? (
          <h2 className='text-center text-danger my-5'>Not able to fetch data at the moment</h2>
        ) : (
          <TableLoading />
        )}
      </div>
    </>
  )
}
