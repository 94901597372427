import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateIntegerState} from '../../../reducers/configurationReducer'

const IntConfig = ({state}: any) => {
  const dispatch = useAppDispatch()

  const handleChange = (e: any) => {
    dispatch(updateIntegerState(parseInt(e.target.value)))
  }

  return (
    <div className='row'>
      <div className='col'>
        <input
          type='number'
          min={1}
          value={state?.value}
          onChange={handleChange}
          className='form-control form-control-lg form-control-solid'
          placeholder='Time'
        />
      </div>
    </div>
  )
}

export default IntConfig
