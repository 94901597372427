import React from 'react'
import InfoWrapper from '../../../modules/common/SectionInfo'
import { useMemberProfile } from '../hooks/useMemberQuery'
import { makeDate } from '../../../../util'
import { useParams } from 'react-router-dom'

type Profile = {
  profileImage: string
  firstName: string
  lastName: string
  mobileNo: string
  address: string
  bio: string
  referralCode: string
  verificationCodeSentAt: Date
  lastLogin: Date
  // loginBy: string
  status: boolean
  createdAt: Date
  email: string
}

const Profile: React.FC = () => {
  const { Id } = useParams()

  const { isLoading, data } = useMemberProfile(Id)

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body px-9 py-2'>
          <div className='my-4'>
            <InfoWrapper title='Personal Information'>
              <div className='row'>
                <div className='col-lg-9 col-12'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>First Name</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.firstName}</p>
                    </div>
                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Last Name</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.lastName}</p>
                    </div>
                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Mobile No.</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.mobileNo}</p>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Username</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.username}</p>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>User ID</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.id}</p>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Email</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.email}</p>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fw-bold text-muted mb-2'>Contact Phone</div>
                      <p className='fs-6 mb-7 fw-bold'>{data?.mobileNo}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-3">
                  <div className='fw-bold text-muted mb-2'>Bio</div>

                  {data?.bio === null ? (
                    <span className=' text-muted  fs-8 d-block mb-7'>No bio available</span>
                  ) : (
                    <div style={{ height: '110px' }} className='fw-bold fs-6 d-block mb-7 overflow-auto'>{data?.bio}</div>
                  )}
                </div>
              </div>
            </InfoWrapper>

            <InfoWrapper title='Address' bar='up'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>State</div>
                      <span className='fw-bold fs-6 d-block mb-7'>{data?.state}</span>
                    </div>

                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>City</div>
                      <span className='fw-bold fs-6 d-block mb-7'>{data?.city}</span>
                    </div>

                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Street</div>
                      <span className='fw-bold fs-6 d-block mb-7'>{data?.street}</span>
                    </div>

                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Zip</div>
                      <span className='fw-bold fs-6 d-block mb-7'>{data?.zip}</span>
                    </div>
                  </div>
                </div>
              </div>
            </InfoWrapper>

            <InfoWrapper title='Basic info' bar='up'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Status</div>
                      <span className={`badge ${data?.status ? 'badge-success' : 'badge-danger'}`}>
                        {!data?.status ? 'N/a' : data?.status ? 'Active' : 'InActive'}
                      </span>
                    </div>
                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Last Login at</div>
                      <span className='badge badge-secondary'>{data?.lastLogin ? makeDate(data?.lastLogin) : 'N/a'}</span>
                    </div>
                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Created at</div>
                      <span className='badge badge-secondary'>{data?.createdAt ? makeDate(data?.createdAt) : 'N/a'}</span>
                    </div>
                    <div className='col-lg-3'>
                      <div className='fw-bold text-muted mb-2'>Verification Code Sent at</div>
                      <span className='badge badge-secondary'>
                        {data?.lastLogin ? makeDate(data?.verificationCodeSentAt) : 'N/a'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </InfoWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile 
