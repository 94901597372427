import client from '../client/client'

const getAllServices = async (params: any) => {
  try {
    return await client.get('api/v1/admin/services', {
      params: params,
    })
  } catch (error) {
    return error
  }
}

const addServices = async (data: any) => {
  try {
    return await client.post('api/v1/admin/service', data)
  } catch (error) {
    console.log('add Services error', error)

    return error
  }
}

const updateServicesRequest = async (data: any, id: string | number) => {
  try {
    return await client.put(`api/v1/admin/service/${id}`, data)
  } catch (error) {
    console.log('update Services error', error)
    return error
  }
}

const updateServiceParentRequest = async (data: any) =>
  await client.put(`api/v1/admin/services/hierarchy/update`, data)

const deleteServices = async (id: any) => {
  try {
    return await client.delete(`api/v1/admin/service/${id}`)
  } catch (error) {
    console.log('add Services error', error)

    return error
  }
}

const copyServicesByParentIdRequest = async (data: any) => {
  try {
    return await client.post('api/v1/admin/copy-services-by-parent-id', data)
  } catch (error) {
    return error
  }
}

export {
  getAllServices,
  addServices,
  deleteServices,
  updateServicesRequest,
  updateServiceParentRequest,
  copyServicesByParentIdRequest,
}
