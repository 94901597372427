import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../store'
import {adminPasswordUpdateRequest, GetProfileRequest} from '../../../api/endpoints/auth'

export interface AuthState {
  isLoggedIn: boolean
  currentUser: CurrentUser
  status: string
  user: any
}

export interface CurrentUser {
  id: number
  firstName: string
  lastName: string
  email: string
  username: string
  mobileNo: string
  profileImage: string
  access_token: string
  refresh_token: string
}

export const initialState: AuthState = {
  isLoggedIn: false,
  status: 'idle',
  user: {},
  currentUser: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    mobileNo: '',
    profileImage: '',
    access_token: '',
    refresh_token: '',
  },
}

export const getProfileAction = createAsyncThunk('get/profile', async (token: string) => {
  try {
    const res: any = await GetProfileRequest(token)
    const {data, success} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const adminUpdatePasswordAction = createAsyncThunk(
  'update/admin-password',
  async (rData: any) => {
    try {
      const res: any = await adminPasswordUpdateRequest(rData.data)
      const {data, success} = res.data
      if (success) {
        rData.SHOW_SUCCESS(true, 'Password updated successfully')
        rData.handleClose()
      }
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {}
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, {payload}: PayloadAction<CurrentUser>) => {
      state.currentUser = payload
      state.isLoggedIn = true
    },
    setLogOut: (state) => {
      state = initialState
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getProfileAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.user = action.payload.data
    })
    builder.addCase(getProfileAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(adminUpdatePasswordAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(adminUpdatePasswordAction.fulfilled, (state, action) => {
      state.status = 'success'
    })
    builder.addCase(adminUpdatePasswordAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const {logIn, setLogOut} = authSlice.actions
export default authSlice.reducer

export const authSelector = (state: RootState) => state.auth
export const getIsLoggedIn = (state: RootState) => state?.auth.isLoggedIn
export const getLoggedInUserDataSelector = (state: RootState) => state?.auth.user
