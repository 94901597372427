import ProfileOverview from './components/Overview'
import RoutingTabs from './components/RoutingTabs'

const MemberHeader = () => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <ProfileOverview />
        <RoutingTabs />
      </div>
    </div>
  )
}

export { MemberHeader }
