/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  title: string
  description: unknown
  avatar: string
}

const StatisticsWidget2: React.FC<Props> = ({className, title, description, avatar}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center py-0 px-0' style={{height: '123px'}}>
        <div className='d-flex flex-column flex-grow-1 me-2 ps-6'>
          <a
            href={title === 'Providers' ? 'provider/list' : 'member/list'}
            className='fw-bold text-dark fs-4 mb-2 text-hover-primary'
          >
            {title}
          </a>

          <span
            className='fw-semibold text-muted fs-5'
            dangerouslySetInnerHTML={{__html: String(description)}}
          ></span>
        </div>
        <div className='position-relative h-100'>
          <img
            style={{
              width: '160px',
              height: '100%',
              borderRadius: '10px',
            }}
            src={`${avatar}`}
            alt='logo'
          />
          <div
            style={{
              background: 'linear-gradient(to right, #fff, transparent)',
              borderRadius: '8px',
              height: '123px',
              width: '160px',
              position: 'absolute',
              top: '0',
            }}
          />
        </div>
      </div>
      {/* <img src={toAbsoluteUrl(avatar)} alt='' className='align-self-end h-100px ghaib' /> */}
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget2}
