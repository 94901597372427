import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Member from '../pages/Member/ListMember'
import {CreateServices} from '../pages/channel/CreateServices'
import {Explore} from '../pages/explore/Explore'
import {ServiceTree} from '../pages/channel/ServiceTree'
import ServicesOne from '../pages/channel/levels/ServicesOne'
import ServicesTwo from '../pages/channel/levels/ServicesTwo'
import ServicesThree from '../pages/channel/levels/ServicesThree'
import IconsListing from '../pages/icons/IconsListing'
import Configuration from '../pages/configuration'
import MemberPage from '../pages/Member/MemberPage'

import {ViewConfig} from '../pages/configuration/components/ViewConfig'
import {Appointments} from '../pages/Appointment/ListAppointment'
import {ViewAppointments} from '../pages/Appointment/components/ViewAppointments'
import {UsersList} from '../pages/users/UsersList'
import CreateUser from '../pages/users/CreateUser'
import FAQ from '../pages/faq/FAQ'
import CreateFaq from '../pages/faq/CreateFaq'
import ViewFaq from '../pages/faq/ViewFaq'

const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
const ProviderPage = lazy(() => import('../pages/Provider/ProviderPage'))
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='/crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* ************************** providers starts here ********************************************** */}

        <Route
          path='/provider/*'
          element={
            <SuspensedView>
              <ProviderPage />
            </SuspensedView>
          }
        />

        {/* ************************** members starts here ********************************************** */}

        <Route
          path='/member/*'
          element={
            <SuspensedView>
              <MemberPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='/members'
          element={
            <SuspensedView>
              <Member />
            </SuspensedView>
          }
        />
        <Route
          path='/members/profile/:Id'
          element={
            <SuspensedView>
              <ViewMembers />
            </SuspensedView>
          }
        />
        <Route
          path='/members/team/:Id'
          element={
            <SuspensedView>
              <MembersTeam />
            </SuspensedView>
          }
        />
        <Route
          path='/members/appointments/:Id'
          element={
            <SuspensedView>
              <MembersAppointments />
            </SuspensedView>
          }
        />
        <Route
          path='/members/connection/:Id'
          element={
            <SuspensedView>
              <MembersConnection />
            </SuspensedView>
          }
        />
        <Route
          path='/members/payment/:Id'
          element={
            <SuspensedView>
              <MembersPayment />
            </SuspensedView>
          }
        /> */}
        {/* ************************** services starts here ********************************************** */}
        <Route
          path='/services/lv1'
          element={
            <SuspensedView>
              <ServicesOne />
            </SuspensedView>
          }
        />
        <Route
          path='/services/lv2/l1/:oneId/l2'
          element={
            <SuspensedView>
              <ServicesTwo />
            </SuspensedView>
          }
        />
        <Route
          path='/services/lv3/l1/:oneId/l2/:twoId/l3'
          element={
            <SuspensedView>
              <ServicesThree />
            </SuspensedView>
          }
        />

        <Route
          path='/services/create'
          element={
            <SuspensedView>
              <CreateServices />
            </SuspensedView>
          }
        />

        <Route
          path='/services/update/:Id'
          element={
            <SuspensedView>
              <CreateServices />
            </SuspensedView>
          }
        />

        {/* ************************** icons starts here ********************************************** */}
        <Route
          path='/icon'
          element={
            <SuspensedView>
              <IconsListing />
            </SuspensedView>
          }
        />

        {/* ************************** users starts here ********************************************** */}
        <Route
          path='/users'
          element={
            <SuspensedView>
              <UsersList />
            </SuspensedView>
          }
        />
        <Route
          path='/users/create'
          element={
            <SuspensedView>
              <CreateUser />
            </SuspensedView>
          }
        />

        {/* ************************** appointment starts here ********************************************** */}
        <Route
          path='/appointments'
          element={
            <SuspensedView>
              <Appointments />
            </SuspensedView>
          }
        />
        <Route
          path='/appointments/:id'
          element={
            <SuspensedView>
              <ViewAppointments />
            </SuspensedView>
          }
        />

        <Route
          path='/explore'
          element={
            <SuspensedView>
              <Explore />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='configuration'
          element={
            <SuspensedView>
              <Configuration />
            </SuspensedView>
          }
        />
        <Route
          path='configuration/view'
          element={
            <SuspensedView>
              <ViewConfig />
            </SuspensedView>
          }
        />

        {/* ************************** faq starts here ********************************************** */}
        <Route
          path='/faq'
          element={
            <SuspensedView>
              <FAQ />
            </SuspensedView>
          }
        />
        <Route
          path='/faq/create'
          element={
            <SuspensedView>
              <CreateFaq />
            </SuspensedView>
          }
        />
        <Route
          path='/faq/:id'
          element={
            <SuspensedView>
              <ViewFaq />
            </SuspensedView>
          }
        />

        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
