/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget11,
  StatisticsWidget2,
  MixedWidget10,
} from '../../../_metronic/partials/widgets'
import {useDispatch} from 'react-redux'
import {AppDispatch} from '../../store'
import useStateQuery, {Statistics} from './hooks/useStateQuery'
import {COLOR} from '../../../config/constant'

const DashboardPage: FC = () => {
  const {isLoading, data, isError, error, isFetching, refetch} = useStateQuery('all')

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <MixedWidget2
            statData={data as Statistics}
            className='card-xl-stretch mb-xl-8'
            chartColor={COLOR.primary}
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        {/* <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}
        {/* <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
      </div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar='/media/svg/kazzah/provider.jpeg'
            title='Providers'
            description={data?.totalProviders}
          />
        </div>

        <div className='col-xl-6'>
          <StatisticsWidget2
            className='card-xl-stretch mb-5 mb-xl-8'
            avatar='/media/svg/kazzah/member.jpeg'
            title='Members'
            description={data?.totalClients}
          />
        </div>
        {/* 
      <div className='col-xl-4'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8'
          avatar='/media/svg/avatars/014-girl-7.svg'
          title='Lisa Bold'
          description='Marketing & Fanance Manager'
        />
      </div> */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
