import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateJson1} from '../../../reducers/configurationReducer'

const Json1Config = ({state}: any) => {
  const dispatch = useAppDispatch()
  const handleChange = (data: any) => {
    dispatch(updateJson1(data))
  }
  return (
    <div className='row'>
      {Object.keys(state?.value?.[Object.keys(state.value)?.[0]]?.[0])?.map(
        (data: any, idx: number) => (
          <div className='col-4'>
            <label className='col-form-label fw-bold fs-6 text-capitalize'>{data}</label>
          </div>
        )
      )}
      {state.value?.[Object.keys(state.value)?.[0]]?.map((social: any, idx: any) =>
        Object.entries(social).map(([key, data]: any, index: number) => (
          <div className='col-4 my-2' key={`${idx}`}>
            <input
              type={state?.value?.rules?.htmlType}
              key={`${idx}-${key}`} // Dynamic key for each input
              onChange={(e) => {
                handleChange({
                  value: e.target.value,
                  idx: idx,
                  parentKey: Object.keys(state.value)[0],
                  key: key, // Key extracted dynamically from object entries
                })
              }}
              value={data}
              className='form-control form-control-lg form-control-solid'
              placeholder=''
            />
          </div>
        ))
      )}
    </div>
  )
}

export default Json1Config
