import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {fetchServices, serviceOneSelector, serviceTwoSelector} from '../../reducers/servicesReducer'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../store'
import {usePathname} from '../../../hooks/usepathName'

type Profile = {}

type ServicesLayoutProps = {
  children: React.ReactNode
}

export default function ServicesLayout({children}: ServicesLayoutProps) {
  const currentLevel = usePathname()
  const Id = useParams()
  // console.log("🚀 ~ file: ServicesLayout.tsx:28 ~ ServicesLayout ~ Id", Id)

  const navigate = useNavigate()
  const levelOne: any = useSelector(serviceOneSelector)
  const levelTwo: any = useSelector(serviceTwoSelector)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchServices({level: 1, parentId: null}))
    return () => {}
  }, [])

  const decideLevel = () => {
    let lvlString
    if (currentLevel.includes('lv2')) {
      lvlString = {text: 'level 2', id: 'l2'}
    } else if (currentLevel.includes('lv3')) {
      lvlString = {text: 'level 3', id: 'l3'}
    } else {
      lvlString = {text: 'level 1', id: 'l1'}
    }

    return lvlString
  }

  return (
    <>
      <div className='row mb-5 mt-n5 pt-n5 align-items-center'>
        <div className='col-6'>
          <h2 className='py-3 mb-0'>Channels</h2>
          {/* {decideLevel().id !== 'l1' ? (
            <button
              onClick={() => navigate(-1)}
              type='button'
              className='btn btn-secondary btn-sm     '
            >
              back
            </button>
            <div className='pointer fs-1 my-0' onClick={() => navigate(-1)}>
              <IoIosArrowBack />
            </div>
          ) : null} */}
        </div>
        <div className='col-6 align-self-center text-end my-3'>
          <Link
            to={
              decideLevel().text === 'level 2'
                ? `/services/create?oneId=${Id?.oneId}`
                : decideLevel().text === 'level 3'
                ? `/services/create?oneId=${Id?.oneId}&twoId=${Id?.twoId}`
                : `/services/create`
            }
          >
            <button type='button' className='btn btn-primary '>
              Create
            </button>
          </Link>
        </div>
      </div>
      <div className='card py-5 px-8'>
        <div className='row'>
          <div className='col-6 text-start px-0 d-flex align-items-center'>
            {decideLevel().text === 'level 2'
              ? levelOne
                  ?.filter((f: any) => f?.id === Id.oneId)
                  ?.map((data: any) => <h2 className='my-auto text-capitalize'>{data?.name}</h2>)
              : null}
            {decideLevel().text === 'level 3'
              ? levelTwo
                  ?.filter((f: any) => f?.id === Id.twoId)
                  ?.map((data: any) => <h2 className='my-auto text-capitalize'>{data?.name}</h2>)
              : null}
            <h2 className='my-auto badge badge-success ms-3'>{decideLevel().text}</h2>
          </div>
        </div>
        {children}
      </div>
    </>
  )
}
