import { Skeleton } from '@mui/material'
import React from 'react'

const ServiceLoading = () => {
    return (
        <>
            {Array(20).fill(20).map((_, index) => (
                <div className='col-3 rounded text-center my-2' key={index}>
                    <Skeleton variant="rounded" height={120} />
                </div>
            ))}
        </>
    )
}

export default ServiceLoading