import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'

const IconCard = ({ icon, handleOpen2, setId }: any) => {
    return (
        <>
            <div
                className='py-4 symbol symbol-35px bg-white text-center bg-white align-items-center d-flex justify-content-around'
                data-bs-toggle='tooltip'
                title='Ana Stone'
            >
                <img style={{ filter: 'grayscale(100%)', height: '60px', width: '60px' }} src={icon?.icon} alt='icon' />
                <img style={{ height: '60px', width: '60px' }} src={icon?.icon} alt='icon' />
            </div>
            <div className='mt-2'>
                <div className='fs-7 text-dark'>{icon?.name}</div>
                <span
                    onClick={() => {
                        handleOpen2()
                        setId(icon?.id)
                    }}
                    // onClick={() => dispatch(deleteIconAction(icon?.id))}
                    className='pointer text-dark hover-danger px-2 py-1 bg-transparent'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Delete'
                >
                    <RiDeleteBin6Line size={'1.1rem'} />
                </span>
            </div>
        </>
    )
}

export default IconCard