import { toast, ToastOptions } from 'react-toastify'

const customId = 'custom-id-yes'


const toast_config: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}


const SHOW_ERROR = (error = true, msg = 'Some thing went wrong') => {
    if (error) {
        toast.error(msg, toast_config)
    }
}

const SHOW_SUCCESS = (success = true, msg = 'Successful !') => {
    // console.log('success', success)

    if (success) {
        toast.success(msg, toast_config)
    }
}

const SHOW_INFO = (success = true, msg = 'information !') => {
    if (success) {
        toast.info(msg, toast_config)
    }
}

export { SHOW_ERROR, SHOW_SUCCESS, SHOW_INFO }