import {KTSVG} from '../../../_metronic/helpers'

type infoPropType = {
  text: string
  iconPath: string
  color?: string
  isLoading?: boolean
}

const Info = ({iconPath, text, color = 'text-gray-400', isLoading}: infoPropType) => {
  return (
    <span className={`d-flex align-items-center ${color}  text-hover-primary me-5 mb-2`}>
      <KTSVG path={iconPath} className='svg-icon-4 me-1' />
      {isLoading ? 'Loading...' : text}
    </span>
  )
}

export default Info
