import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { MemberAppointment, MemberConnection, MemberProfile, MemberTeam, MemberTopHeader } from './components'
import ListMember from './ListMember'

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Member',
        path: '/member/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const MemberPage = () => {

    return (
        <Routes>
            <Route
                path='list'
                element={
                    <>
                        {/* <PageTitle breadcrumbs={profileBreadCrumbs}>list</PageTitle> */}
                        <ListMember />
                    </>
                }
            />

            <Route
                path='/:Id'
                element={
                    <>
                        <MemberTopHeader />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='profile'
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs}>profile</PageTitle>
                            <MemberProfile />
                        </>
                    }
                />
                <Route
                    path='team'
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs}>Member List</PageTitle>
                            <MemberTeam />
                        </>
                    }
                />
                <Route
                    path='appointment'
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs}>appointment</PageTitle>
                            <MemberAppointment />
                        </>
                    }
                />
                <Route
                    path='connections'
                    element={
                        <>
                            <PageTitle breadcrumbs={profileBreadCrumbs}>schedule</PageTitle>
                            <MemberConnection />
                        </>
                    }
                />
            </Route>

            <Route index element={<Navigate to='/provider/list' />} />
        </Routes>
    )
}

export default MemberPage

// export const ProviderHeader = () => {
//   return <div>ProviderPage</div>
// }
