import React from 'react'
import InfoWrapper from '../../../modules/common/SectionInfo'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { NA } from '../../../../config/constant'

type card = {
    image: string,
    firstName: string,
    lastName: string,
    title: string,
}

const UserCard = ({ image, firstName, lastName, title }: card) => {
    return (
        <div className="card mb-5 mb-xl-10">
            <div className='card-body px-9 py-5'>
                <InfoWrapper title={title}>
                    <div className='row aling-items-center'>
                        <div className='col-lg-6'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'>
                                <img
                                    src={!image ? toAbsoluteUrl('/media/avatars/blank.png') : image}
                                    alt='provider avatar'
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='fw-bold text-muted mb-2'>Name</div>
                            <p className='fs-6 mb-7 fw-bold'>{firstName ? firstName : NA} {lastName}</p>
                        </div>
                    </div>
                </InfoWrapper>
            </div>
        </div>
    )
}

export default UserCard