import {useLocation} from 'react-router-dom'

// custom hook to get the current pathname in React

export const usePathname = () => {
  const location = useLocation()
  // console.log('pathname => ', location.pathname)

  return location.pathname
}
