import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {GlobalState} from '../../../../app/contexts/AuthContext'
import {Languages} from './Languages'

const HeaderUserMenu = () => {
  const globalState: any = useContext(GlobalState)
  /**TODO : remove any
   * add proper type
   */
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {/* {currentUser?.first_name} {currentUser?.first_name} */}
              {globalState.globalState.currentUser.name
                ? globalState.globalState.currentUser.name
                : 'admin game'}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {/* {currentUser?.email} */}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/profile/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div
        onClick={() => {
          globalState.setGlobalState({isLoggedIn: false})
          localStorage.removeItem('token')
          // TODO : test and changes
          // also make UI Update , so user will know it logged out
        }}
        className='menu-item px-5'
      >
        <span className='menu-link px-5'>Sign Out</span>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
