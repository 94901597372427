import { useCallback } from "react";
import { useQuery } from "react-query";
import { getStatistics } from "../../../../api/endpoints/statistic";

export interface Statistics {
    totalProviders?: number,
    totalClients?: number,
    totalAppointments?: number,
    totalActiveAppointments?: number,
    totalRootServices?: number,
    totalLevel2Services?: number,
    totalLevel3Services?: number
}

const useStateQuery = (name: string | undefined = undefined) => {
    const query = useQuery({
        queryKey: "stats",
        queryFn: getStatistics,
        select: (data): Statistics => {

            if (name === "all") {
                return data.data.data;
            }
            else if (name) {
                return data.data.data[name] ?? undefined;
            } else {
                console.error("selector name is not correct");
                return {}
            }
        }
    });

    return query;
};

export default useStateQuery;
