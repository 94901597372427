import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as util from '../../../../../../util'

const RoutingTabs = () => {
  const location = useLocation()
  const { Id } = useParams()

  return (
    <div className='d-flex overflow-auto h-55px'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ${util.matchUrl(`/member/${Id}/profile`, location.pathname) ? 'active' : ''
              }`}
            to={`/member/${Id}/profile`}
          >
            Profile
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ${util.matchUrl(`/member/${Id}/team`, location.pathname) ? 'active' : ''
              }`}
            to={`/member/${Id}/team`}
          >
            Team
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ${util.matchUrl(`/member/${Id}/appointment`, location.pathname) ? 'active' : ''
              }`}
            to={`/member/${Id}/appointment`}
          >
            Appointments
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ${util.matchUrl(`/member/${Id}/connections`, location.pathname) ? 'active' : ''
              }`}
            to={`/member/${Id}/connections`}
          >
            Connections
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default RoutingTabs
