import React, {useState} from 'react'
import {useAppDispatch} from '../../../store'
import {updateJson3State} from '../../../reducers/configurationReducer'
import {Draggable} from 'react-drag-reorder'
import {MdDragIndicator} from 'react-icons/md'

const Json3Config = ({state}: any) => {
  const dispatch = useAppDispatch()

  // state = {
  //   words: ['Hello', 'Hi', 'How are you', 'Cool'],
  // }

  const getChangedPos = (currentPos: number, newPos: number) => {
    // Assuming state.value is an object
    const values: any = Object.values(state.value).filter((val) => val !== 'rules')

    // Reorder the values based on the changed positions
    const movedValue = values.splice(currentPos, 1)[0]
    values.splice(newPos, 0, movedValue)

    // Update the state with the new order
    const finalData = {
      ...state.value,
      ...values.reduce(
        (acc: any, val: any, idx: number) => ({...acc, [(idx + 1).toString()]: val}),
        {}
      ),
    }

    // Extract the "rules" object
    const rulesObject = finalData.rules

    // Filter the "rules" object and remove key-value pairs with object values
    const filteredObject = Object.fromEntries(
      Object.entries(finalData).filter(
        ([key, value]) => key !== 'rules' && typeof value !== 'object'
      )
    )

    // Add back the "rules" key-value pair
    filteredObject.rules = rulesObject

    dispatch(updateJson3State(filteredObject))
  }

  return (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='row mb-4 text-start d-flex flex-column'>
          <Draggable onPosChange={getChangedPos}>
            {Object.entries(state?.value).map(([key, value]: any) => {
              if (key === 'rules') {
                return null
              }
              return (
                <div key={key} className='d-flex bg-light align-items-center p-1 border my-1'>
                  <MdDragIndicator /> <div>{value}</div>
                </div>
              )
            })}
          </Draggable>
        </div>
      </div>
    </div>
  )
}

export default Json3Config
