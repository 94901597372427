import {useState, useEffect} from 'react'

function useSvgColor(svgUrl: any, color?: any) {
  const [svgText, setSvgText] = useState(null)

  useEffect(() => {
    const fetchSvg = async () => {
      const response = await fetch(svgUrl)
      const text = await response.text()
      const startIndex = text.indexOf('<svg')
      const endIndex = text.lastIndexOf('</svg>') + 6
      const coloredSvg = text
        .substring(startIndex, endIndex)
        .replace(/#([0-9A-Fa-f]{6})/g, color)
        .replace('<svg', '<svg width="50" height="50"')
      const originalSvg = text
        .substring(startIndex, endIndex)
        .replace('<svg', '<svg width="50" height="50"')
      if (color) {
        setSvgText(coloredSvg as any)
      } else {
        setSvgText(originalSvg as any)
      }
    }
    fetchSvg()
  }, [svgUrl, color])

  return svgText
}

export default useSvgColor
