import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuth } from '../../app/modules/auth/core/AuthHelpers';

const baseURL = process.env.REACT_APP_API_BASE_URL;

console.log("current  API URL : ", baseURL);

const client: AxiosInstance = axios.create({
    baseURL,
    // Additional configuration options
});



const isDev: boolean = process.env.NODE_ENV === 'development';

if (isDev) {
    console.info('You are in a development environment :).');
} else {
    console.info('You are in a production environment.');
}



// Request interceptor
client.interceptors.request.use(
    (config: any) => {
        const token = getAuth()
        if (token?.access_token) {
            config.headers.Authorization = `Bearer ${token.access_token}`
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);




export default client;
