import {Box, Modal, Typography} from '@mui/material'
import React from 'react'
import {useAppDispatch} from '../../../store'

const DeleteModal = ({open, handleClose, deleteFunc, id}: any) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: 300, lg: 600},
    bgcolor: '#1e1e2d',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const dispatch = useAppDispatch()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography
          className='text-center text-white'
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          Are you sure to delete it ?
        </Typography>
        <div className='d-flex justify-content-between mt-4 mx-4'>
          <div>
            <button
              onClick={() => {
                deleteFunc(id)
                handleClose()
              }}
              type='button'
              className='btn btn-success'
              aria-label='submit data'
            >
              Yes
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                handleClose()
              }}
              type='button'
              className='btn btn-danger'
              aria-label='submit data'
            >
              No
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default DeleteModal
