import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateJson4State} from '../../../reducers/configurationReducer'

const Json4Config = ({state}: any) => {
  const dispatch = useAppDispatch()
  const handleChange = (data: any) => {
    dispatch(updateJson4State(data))
  }
  return (
    <div className='row'>
      {Object.keys(state?.value?.[0])?.map((data: any, idx: number) => (
        <div className='col-4'>
          <label className='col-form-label fw-bold fs-6 text-capitalize'>{data}</label>
        </div>
      ))}
      {state?.value?.map((item: any, index: any) =>
        Object.entries(item).map(([key, value]: any) => (
          <div key={index} className='col-4 my-2'>
            <input
              key={key}
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={value}
              onChange={(e: any) =>
                handleChange({
                  value: e.target.value,
                  index: parseInt(index),
                  key: key, // Key extracted dynamically from object entries
                })
              }
              placeholder={key}
            />
          </div>
        ))
      )}
    </div>
  )
}

export default Json4Config
