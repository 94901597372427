import {useEffect, useState} from 'react'
import {CgPathDivide} from 'react-icons/cg'
import Box from '@mui/material/Box'
import {AiFillPlusCircle} from 'react-icons/ai'
import {fetchIconAction} from '../../reducers/iconReducer'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../store'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {
  fetchServices,
  serviceOneSelector,
  serviceThreeSelector,
  serviceTwoSelector,
  updateServicesAction,
} from '../../reducers/servicesReducer'
import {SHOW_SUCCESS} from '../../../util'
import {addServices, getAllServices} from '../../../api/endpoints/service'
import InfoWrapper from '../../modules/common/SectionInfo'
import {MuiTooltip} from '../../common/Tooltip'
import {useForm, SubmitHandler, useController} from 'react-hook-form'
import ModalC from '../../modules/common/Modal'
import AddNewChannel from './components/AddNewChannel'
import IconsLibrary from './components/IconsLibrary'
import AddNewSubChannel from './components/AddNewSubChannel'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: 600,
  overflow: 'scroll',
}

interface FormFields {
  searchAbleId: string
  parentId: string
  name: string
  icon: string
  description: string
  duration: string
  price: string
  isActive: boolean
  isAdmin: boolean
}

const CreateServices = () => {
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState({
    iconModel: false,
    channelModel: false,
    SubChannelModel: false,
  })
  const [libraryIcon, setLibraryIcon] = useState('')
  const [prevData, setPrevData] = useState({})

  const levelOne: any = useSelector(serviceOneSelector)
  const levelTwo: any = useSelector(serviceTwoSelector)
  const levelThree: any = useSelector(serviceThreeSelector)

  const {
    register: submit,
    handleSubmit: handleSubmitForm,
    control,
    formState: {errors, defaultValues},
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<FormFields>({
    defaultValues: {
      searchAbleId: '',
      parentId: '',
      name: '',
      icon: '',
      description: '',
      duration: '',
      price: '',
      isActive: false,
      isAdmin: false,
    },
  })
  const {field} = useController({name: 'searchAbleId', control})
  useEffect(() => {
    setValue('icon', libraryIcon)
  }, [libraryIcon])

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const finalData = {
      ...data,
      isActive: data.isActive ? 1 : 0,
      isAdmin: data.isActive ? 1 : 0,
      description: data?.description?.replace(/\s+/g, ' '),
      level: '3',
    }
    if (Id) {
      dispatch(updateServicesAction({data: finalData, id: Id, level: 3}))
    } else {
      let newData = {...finalData, isAdmin: 1}
      const res: any = await addServices(newData)
      const {data, success, message} = res.data
      if (success) {
        SHOW_SUCCESS(success, 'service  successfully !')
        dispatch(fetchServices({level: 1, parentId: null}))
        navigate('/services/lv1')
      }
    }
  }

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    // getLevel3()
    dispatch(fetchServices({level: 1, parentId: null}))
    dispatch(fetchServices({level: 2, parentId: oneId}))
    dispatch(fetchServices({level: 3, parentId: twoId}))
    dispatch(fetchIconAction())
  }, [])

  const {Id} = useParams<{Id: string}>()
  const oneId = searchParams.get('oneId')
  const twoId = searchParams.get('twoId')

  const navigate = useNavigate()
  useEffect(() => {
    if (Id) {
      const child: any = levelThree?.filter((item: any) => item.id == Id)
      const subParent: any = levelTwo?.filter((item: any) => item.id == child?.[0]?.parentId)

      const parent: any = levelOne?.filter((item: any) => item.id == subParent?.[0]?.parentId)
      reset({
        searchAbleId: parent?.[0]?.id,
        parentId: child?.[0]?.parentId,
        name: child?.[0]?.name,
        description: child?.[0]?.description,
        icon: child?.[0]?.icon,
        price: child?.[0]?.price,
        duration: child?.[0]?.duration,
        isActive: child?.[0]?.isActive,
        isAdmin: child?.[0]?.isAdmin,
      })
      setPrevData({
        searchAbleId: parent?.[0]?.id,
        parentId: child?.[0]?.parentId,
        name: child?.[0]?.name,
        description: child?.[0]?.description,
        icon: child?.[0]?.icon,
        price: child?.[0]?.price,
        duration: child?.[0]?.duration,
        isActive: child?.[0]?.isActive,
        isAdmin: child?.[0]?.isAdmin,
      })
    }
    if (oneId !== null && twoId !== null) {
      reset({
        searchAbleId: oneId,
        parentId: twoId,
      })
    } else if (oneId !== null) {
      reset({
        searchAbleId: oneId,
      })
    }
  }, [Id, levelOne, levelThree, levelTwo, oneId, reset, twoId])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          {Id ? (
            <h3 className='fw-bolder m-0'>Update Channel</h3>
          ) : (
            <h3 className='fw-bolder m-0'>Create Channel</h3>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmitForm(onSubmit)} className='form'>
        <div className='card-body border-top px-9 pb-9 pt-0'>
          <InfoWrapper bar={'down'}>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className={`${!Id && 'required'}`}>Channel</span>
                </label>
                <div className='d-flex'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...submit('searchAbleId', {required: 'Channel is required'})}
                    value={field.value}
                    disabled={Id ? true : false}
                  >
                    <option value=''>Select a Channel...</option>
                    {levelOne?.map((u: any) => (
                      <option value={u?.id}>{u?.name}</option>
                    ))}
                  </select>
                  {!Id && (
                    <MuiTooltip title='Add New Channel'>
                      <div
                        onClick={() => setOpen({...open, channelModel: true})}
                        className='text-center m-auto ms-2'
                      >
                        <AiFillPlusCircle className='me-3 pointer' size={'1.8rem'} />
                      </div>
                    </MuiTooltip>
                  )}
                </div>
                {errors.searchAbleId?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.searchAbleId?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className={`${!Id && 'required'}`}>Sub Channel</span>
                </label>
                <div className='d-flex'>
                  <select
                    {...submit('parentId', {required: 'Sub Channel is required'})}
                    disabled={Id ? true : false}
                    className='form-select form-select-solid form-select-lg fw-bold'
                  >
                    <option value=''>Select a Sub Channel...</option>
                    {levelTwo?.map((u: any) => (
                      <option value={u?.id}>{u.name}</option>
                    ))}
                  </select>
                  {!Id && (
                    <MuiTooltip title='Add New Sub Channel'>
                      <div
                        onClick={() => setOpen({...open, SubChannelModel: true})}
                        className='text-center m-auto ms-2'
                      >
                        <AiFillPlusCircle className='me-3 pointer' size={'1.8rem'} />
                      </div>
                    </MuiTooltip>
                  )}
                </div>
                {errors.parentId?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.parentId?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfoWrapper>
          <InfoWrapper>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='col-form-label required fw-bold fs-6'>Service Name</label>
                <input
                  {...submit('name', {required: 'Service Name is required'})}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Service Name'
                />
                {errors.name?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.name?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Service Icon</span>
                </label>
                <div className='d-flex'>
                  <input
                    type='url'
                    {...submit('icon', {required: 'Service Icon is required'})}
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Icon Link'
                  />
                  <MuiTooltip title='Add Icon from Library'>
                    <div
                      onClick={() => setOpen({...open, iconModel: true})}
                      className='text-center m-auto ms-2'
                    >
                      <CgPathDivide className='me-3 pointer' size={'2rem'} />
                    </div>
                  </MuiTooltip>
                </div>
                {errors.icon?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.icon?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfoWrapper>
          <InfoWrapper>
            <div className='row'>
              <div
                className='col-12'
                style={{
                  position: 'relative',
                }}
              >
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Description</span>
                </label>
                <textarea
                  {...submit('description', {
                    required: 'Description is required',
                    maxLength: {
                      value: 255,
                      message: 'Max length should 255 chars only',
                    },
                  })}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  style={{
                    resize: 'none',
                  }}
                  rows={3}
                />
                {errors.description?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.description?.message}</span>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <small style={{fontSize: '12px'}} className=''>
                    {watch('description')?.length}/{'255'}
                  </small>
                </div>
              </div>
            </div>
          </InfoWrapper>
          <InfoWrapper bar={'down'}>
            <div className='row mb-10'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Duration</span>
                </label>

                <input
                  {...submit('duration', {required: 'Duration is required', min: 1})}
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  min={0}
                  placeholder='Duration'
                />
                {errors.duration?.type === 'required' ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.duration?.message}</span>
                    </div>
                  </div>
                ) : errors.duration?.type === 'min' ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>Duration should not be negative</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Price</span>
                </label>

                <input
                  {...submit('price', {required: 'Price is required', min: -1})}
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  min={0}
                  placeholder='Price'
                />
                {errors.price?.type === 'required' ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.price?.message}</span>
                    </div>
                  </div>
                ) : errors.price?.type === 'min' ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>Price should not be negative</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </InfoWrapper>
          <InfoWrapper>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='row mb-4 text-start'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Active</label>

                  <div className='col-lg-8 d-flex align-items-center'>
                    <div className='form-check form-check form-switch fv-row'>
                      <input
                        {...submit('isActive', {required: false})}
                        className={`form-check-input w-45px h-30px`}
                        type='checkbox'
                        id='isActive'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row mb-4 text-start'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Admin</label>

                  <div className='col-lg-8 d-flex align-items-center'>
                    <div className='form-check form-check form-switch fv-row'>
                      <input
                        {...submit('isAdmin', {required: false})}
                        className={`form-check-input w-45px h-30px`}
                        type='checkbox'
                        id='isAdmin'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoWrapper>
          {/* <div className='row mb-0'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Is Default</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  id='allowmarketing'
                />
                <label className='form-check-label'></label>
              </div>
            </div>
          </div> */}
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={prevData == defaultValues}>
            {Id ? 'Update' : 'Save'}
          </button>
        </div>
      </form>
      <ModalC handleClose={() => setOpen({...open, iconModel: false})} open={open.iconModel}>
        <Box sx={style}>
          <IconsLibrary open={open} setOpen={setOpen} setLibraryIcon={setLibraryIcon} />
        </Box>
      </ModalC>
      <ModalC handleClose={() => setOpen({...open, channelModel: false})} open={open.channelModel}>
        <AddNewChannel open={open} setOpen={setOpen} libraryIcon={libraryIcon} />
      </ModalC>
      <ModalC
        handleClose={() => setOpen({...open, SubChannelModel: false})}
        open={open.SubChannelModel}
      >
        <AddNewSubChannel
          open={open}
          setOpen={setOpen}
          libraryIcon={libraryIcon}
          parents={levelOne}
          searchAbleId={field.value}
          setLibraryIcon={setLibraryIcon}
        />
      </ModalC>
    </div>
  )
}

export {CreateServices}
