import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import Info from '../../../../../modules/common/Info'
import SquareState, { ArrowDirections } from '../../../../../modules/common/SquareState'
import { useMemberProfile } from '../../../hooks/useMemberQuery'

const Overview = () => {
  const { Id } = useParams()

  const { isLoading, data } = useMemberProfile(Id)

  return (
    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
      <div className='me-7 mb-4'>
        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border'>
          <img
            src={!data?.profileImage ? toAbsoluteUrl('/media/avatars/blank.png') : data?.profileImage}
            alt='provider avatar'
            style={{ objectFit: 'contain' }}
          />
          <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
        </div>
      </div>

      <div className='flex-grow-1'>
        {/* top section */}
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex flex-column'>
            {/* profile image */}
            <div className='d-flex align-items-center mb-2'>
              <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                {false ? 'Loading...' : data?.username}
              </span>
              <span>
                <KTSVG
                  path='/media/icons/duotune/general/gen026.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </span>
            </div>

            {/* basic info */}
            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
              <Info
                iconPath='/media/icons/duotune/general/gen018.svg'
                text={`${data?.state}, ${data?.city}`}
                isLoading={false}
              />
              <Info
                iconPath='/media/icons/duotune/communication/com011.svg'
                text={data?.email}
                isLoading={false}
              />
            </div>
          </div>

          <div className='d-flex my-4'>
            <button
              className='btn btn-sm btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_offer_a_deal'
            >
              Action
            </button>
          </div>
        </div>

        {/* bottom section */}
        <div className='d-flex flex-wrap flex-stack'>
          <div className='d-flex flex-column flex-grow-1 pe-8'>
            <div className='d-flex flex-wrap'>
              <SquareState title='Amount Spent' value={`13450$`} />
              <SquareState title='Appointments' value={data?.appointments?.length} />
              {/* <SquareState title='Client Served' value={`490`} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
