import React, { useEffect, useState } from 'react'
import { addIconsRequest } from '../../../api/endpoints/icon'
import { KTSVG } from '../../../_metronic/helpers'
import { BiSelectMultiple } from 'react-icons/bi'
import { GrCheckboxSelected } from 'react-icons/gr'
import { BiSelection } from 'react-icons/bi'
import Modal from '@mui/material/Modal'
import { RiDeleteBin6Line } from 'react-icons/ri'
import Box from '@mui/material/Box'
// import { useDispatch, useSelector } from 'react-redux'
// import { deleteIconAction, fetchIconAction, iconListSelector } from '../../reducers/iconReducer'
// import { AppDispatch } from '../../store'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  addIconAction,
  deleteIconAction,
  fetchIconAction,
  iconListSelector,
  statusSelector,
} from '../../reducers/iconReducer'
import { AppDispatch } from '../../store'
import { toast, ToastContainer } from 'react-toastify'
import useSvgColor from '../../../hooks/useSvgColor'
import VersionIcons from './components/VersionIcons'
import IconCard from './components/IconCard'
import IconLoading from './components/IconLoading'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 300, lg: 600 },
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const IconsListing = () => {
  const [id, setId] = useState(0)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  // const [submissionData, setSubmissionData] = useState<any>()
  const [submissionData, setSubmissionData] = useState<any>({
    name: '',
    icon: '',
  })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)

  const [previewIcon, setPreviewIcon] = useState('')
  const originalIcon = useSvgColor(previewIcon)
  const coloredIcon = useSvgColor(previewIcon, '#bfbfbf')

  const [error, setError] = useState<any>({
    name: false,
    icon: false,
  })

  const iconList = useSelector(iconListSelector)
  const status = useSelector(statusSelector)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchIconAction())
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('icon', submissionData?.icon)
    formData.append('name', submissionData?.name)

    if (!submissionData?.name) {
      setError({
        ...error,
        name: true,
      })
      return
    } else if (!submissionData?.icon) {
      setError({
        ...error,
        icon: true,
      })
      return
    } else {
      dispatch(addIconAction({ data: formData, handleClose }))
    }
  }

  const handleFileChange = (e: any) => {
    setPreviewIcon(URL.createObjectURL(e.target.files[0]))
    e.preventDefault()
    const file = e.target.files[0]
    if (file.type !== 'image/svg+xml') {
      toast.error('File should be only svg', {
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      return
    } else {
      setSubmissionData({
        ...submissionData,
        icon: file,
      })
    }
  }

  // const notify = () => toast('Wow so easy!')

  return (
    <>
      <div className='row mb-5 mt-n5 pt-n5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Icons</h2>
        </div>
        {/* <div className="col-6 align-self-center text-end">
                    <AppointmentlistFilter />
                </div> */}
      </div>
      <div className='card mb-5 mb-xl-10 py-4'>
        {/* search section */}
        <div className='d-flex align-items-center justify-content-end my-3 px-4'>
          <button onClick={handleOpen} type='button' className='btn btn-primary '>
            Create
          </button>
        </div>
        <div className='mx-4 rounded'>
          <div className='row mt-8 bg-secondary w-100 mx-auto p-3 rounded gy-5'>
            {status === 'loading' ?
              <IconLoading />
              :
              iconList?.map((icon: any) => (
                <div className='col-2 rounded text-center'>
                  <IconCard icon={icon} handleOpen2={handleOpen2} setId={setId} />
                </div>
              ))
            }
            <Modal
              open={open2}
              onClose={handleClose2}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <Typography
                  className='text-center text-white'
                  id='modal-modal-title'
                  variant='h6'
                  component='h2'
                >
                  Are you sure to delete it ?
                </Typography>
                <div className='d-flex justify-content-between mt-4 mx-4'>
                  <div>
                    <button
                      onClick={() => {
                        handleClose2()
                        dispatch(deleteIconAction(id))
                      }}
                      type='button'
                      className='btn btn-success'
                      aria-label='submit data'
                    >
                      Yes
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        handleClose2()
                      }}
                      type='button'
                      className='btn btn-danger'
                      aria-label='submit data'
                    >
                      No
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          handleClose()
          setError({
            name: false,
            icon: false,
          })
          setSubmissionData({
            name: '',
            icon: '',
          })
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder text-white'>Add Icons</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={() => {
                handleClose()
                setError({
                  name: false,
                  icon: false,
                })
                setSubmissionData({
                  name: '',
                  icon: '',
                })
              }}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <form className='m-5' onSubmit={(e) => handleSubmit(e)}>
            <div className='fv-row my-1'>
              <input
                type='text'
                className={`form-control form-control-lg form-control-solid ${error?.name ? 'border-danger border border-2 is-invalid' : ''
                  }`}
                placeholder='Icon Name'
                onChange={(e) => {
                  setSubmissionData({
                    ...submissionData,
                    name: e.target.value,
                  })
                  setError({
                    ...error,
                    name: false,
                  })
                }}
              />
              {error?.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger'>Icon name is required</div>
                </div>
              )}
            </div>
            <div className='fv-row mt-4'>
              <div className='input-group' style={{ color: 'rgb(102, 112, 133)' }}>
                <input
                  value={submissionData?.icon?.name}
                  name='image'
                  type='text'
                  disabled={true}
                  className={`form-control form-control-lg form-control-solid ${error?.icon ? 'border-danger border border-2 is-invalid' : ''
                    }`}
                  id='image'
                  placeholder='No file chosen'
                />
                <label>
                  <input
                    onChange={(e) => {
                      handleFileChange(e)
                      setError({
                        ...error,
                        icon: false,
                      })
                    }}
                    name='image'
                    type='file'
                    className='form-control py-2'
                    id='image'
                    placeholder='www.udemy.com'
                    hidden
                  />
                  <span
                    className='input-group-text px-md-3 px-1 h-100 pointer bg-white fw-bold rounded-start-0'
                    id='basic-addon1'
                    style={{ color: 'rgb(102, 112, 133)' }}
                  >
                    Browse
                  </span>
                </label>
              </div>
              {/* <input
                // accept='image/*'
                type='file'
                className={`form-control form-control-lg form-control-solid ${error?.icon ? 'border-danger border border-2 is-invalid' : ''
                  }`}
                placeholder='Upload icon'
              /> */}
              {error?.icon && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger'>Icon is required</div>
                </div>
              )}
            </div>
            <div className='rounded'>
              {submissionData.icon ? (
                <div className='row mt-4 bg-secondary w-100 mx-auto px-3 pb-3 pt-0 rounded gy-5'>
                  <div className='col-6 rounded text-center'>
                    <div
                      className='py-4 symbol symbol-35px bg-white text-center bg-white align-items-center d-flex justify-content-center'
                      data-bs-toggle='tooltip'
                      title='Ana Stone'
                    >
                      {/* <img className='shadow-lg' src={previewIcon} alt='icon' height='50' width={50} /> */}
                      {originalIcon && <div dangerouslySetInnerHTML={{ __html: originalIcon }} />}
                    </div>
                    <div className='mt-2'>
                      <div className='fs-7 text-dark'>{submissionData?.name}</div>
                    </div>
                  </div>
                  <div className='col-6 rounded text-center'>
                    <div
                      className='py-4 symbol symbol-35px bg-white text-center bg-white align-items-center d-flex justify-content-center'
                      data-bs-toggle='tooltip'
                      title='Ana Stone'
                    >
                      {/* <img className='shadow-lg' src={svg} alt='icon' height='50' width={50} /> */}
                      {coloredIcon && <div dangerouslySetInnerHTML={{ __html: coloredIcon }} />}
                    </div>
                    <div className='mt-2'>
                      <div className='fs-7 text-dark'>{submissionData?.name}</div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {status === 'loading' ? (
              <h2 className='text-white mt-4 text-center'>Loading...</h2>
            ) : (
              <div className='text-center'>
                <button type='submit' className='btn btn-primary mt-4 ' aria-label='submit data'>
                  Submit
                </button>
              </div>
            )}
          </form>
        </Box>
      </Modal>
    </>
  )
}

export default IconsListing
