import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addUsersRequest, getAllUsersRequest} from '../../../api/endpoints/user'
import {RootState} from '../../store'

interface userState {
  users: Array<any>
  status: string
  error: string | null
}

export const initialState: userState = {
  users: [],
  status: 'idle',
  error: null,
}

export const addUserAction = createAsyncThunk('post/user', async (rData: any) => {
  try {
    const res: any = await addUsersRequest(rData.data)
    const {data, success} = res.data
    if (success) {
      rData.SHOW_SUCCESS(true, 'User added successfully')
      rData.navigate('/users')
    }
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

export const getAllUsersAction = createAsyncThunk('get/all-users', async () => {
  try {
    const res: any = await getAllUsersRequest()
    const {data, success} = res.data
    return {
      data,
      success,
      code: 200,
    }
  } catch (error) {
    return error
  }
})

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(addUserAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(addUserAction.fulfilled, (state, action) => {
      state.status = 'success'
      console.log('adara', action.payload)
    })
    builder.addCase(addUserAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(getAllUsersAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(getAllUsersAction.fulfilled, (state, action: any) => {
      state.status = 'success'
      state.users = action.payload.data
    })
    builder.addCase(getAllUsersAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const usersListSelector = (state: RootState) => state.users.users
export const usersStatusSelector = (state: RootState) => state.users.status

export default userSlice.reducer
