import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {BiEditAlt} from 'react-icons/bi'
import settings from '../../../../config/settings.json'
import moment from 'moment'
import InfoWrapper from '../../../modules/common/SectionInfo'
import {makeDate} from '../../../../util'
import {formatLocalTime} from '../../../../util/helper'
import {NA} from '../../../../config/constant'
import UserCard from './UserCard'
import ViewAppointmentsService from './ViewAppointmentsService'
import {useAppointmentDetail} from '../hooks/useAppointmentQuery'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const ViewAppointments: React.FC = () => {
  //  modal handlers
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleOpen1 = () => setOpen1(true)
  const handleClose = () => setOpen(false)
  const handleClose1 = () => setOpen1(false)

  const {id}: any = useParams()

  const {isLoading, data} = useAppointmentDetail(id)

  // console.log('appointment data', appointmentData)

  return (
    <>
      <div className='row mb-5'>
        <div className='col-6'>
          <h2 className='py-3 mb-2'>Appointment Detail</h2>
        </div>
        {/* <div className='col-6 align-self-center text-end'>
          <button onClick={handleOpen1} type='button' className='btn btn-primary mx-2'>
            Edit
          </button>
          <button onClick={handleOpen} type='button' className='btn btn-primary'>
            Reschedule
          </button>
        </div> */}
      </div>
      <div className='mt-4'>
        <div className='row'>
          <div className='col'>
            <UserCard
              image={data?.provider?.profileImage}
              firstName={data?.provider?.firstName}
              lastName={data?.provider?.lastName}
              title={'Provider'}
            />
          </div>
          <div className='col'>
            <UserCard
              image={data?.client?.profileImage}
              firstName={data?.client?.firstName}
              lastName={data?.client?.lastName}
              title={'Member'}
            />
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-body px-9 py-2'>
          <div className='mt-4'>
            <InfoWrapper title='Appointments' bar='down'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Date</div>
                  <p className='fs-6 mb-7 fw-bold'>
                    {data?.appointmentDate ? makeDate(data?.appointmentDate) : NA}
                  </p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Time</div>
                  <p className='fs-6 mb-7 fw-bold'>
                    <span className='badge badge-secondary'>
                      {formatLocalTime(data?.startTime ? data?.startTime : NA)}
                    </span>{' '}
                    -{' '}
                    <span className='badge badge-secondary'>
                      {formatLocalTime(data?.endTime ? data?.endTime : NA)}
                    </span>
                  </p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Status</div>
                  <p className='fs-6 mb-7 fw-bold text-capitalize'>
                    {data?.status ? data?.status : NA}
                  </p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Duration</div>
                  <p className='fs-6 mb-7 fw-bold'>
                    {data?.totalDuration ? data?.totalDuration : NA} min
                  </p>
                </div>
              </div>
            </InfoWrapper>
          </div>
          <div className='mt-4'>
            <InfoWrapper title='Payments' bar='down'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Fee</div>
                  <p className='fs-6 mb-7 fw-bold'>{data?.fee ? data?.fee : NA}</p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Discount</div>
                  <p className='fs-6 mb-7 fw-bold'>{data?.discount ? data?.discount : NA}</p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Status</div>
                  <p className='fs-6 mb-7 fw-bold'>
                    {data?.isPaid === 1 ? (
                      <span className='text-success'>Paid</span>
                    ) : (
                      <span className='text-danger'>Unpaid</span>
                    )}
                  </p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Tax</div>
                  <p className='fs-6 mb-7 fw-bold'>{data?.tax ? data?.tax : NA}</p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Total</div>
                  <p className='fs-6 mb-7 fw-bold'>{data?.totalPrice ? data?.totalPrice : NA}</p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Miscellaneous Fee</div>
                  <p className='fs-6 mb-7 fw-bold'>{data?.miscFee ? data?.miscFee : NA}</p>
                </div>
                <div className='col-lg-4'>
                  <div className='fw-bold text-muted mb-2'>Reservation Fee</div>
                  <p className='fs-6 mb-7 fw-bold'>
                    {data?.reservationFee ? data?.reservationFee : NA}
                  </p>
                </div>
              </div>
            </InfoWrapper>
          </div>
          <div className='mt-4'>
            <InfoWrapper title='Services' bar='down'>
              <ViewAppointmentsService services={data?.services} />
            </InfoWrapper>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder text-white'>Reschedule</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <form className='text-center m-5'>
            <div className='mb-4 mt-4 row text-start'>
              <label className='col-form-label col-sm-3' htmlFor='name'>
                Date
              </label>

              <div className='col-sm-9 my-auto'>
                <input
                  type='date'
                  className='form-control'
                  id='name'
                  placeholder='Current Password'
                />
              </div>
            </div>
            <div className='mb-4 row text-start'>
              <label className='col-form-label col-sm-3' htmlFor='name'>
                Start Time
              </label>

              <div className='col-sm-9 my-auto'>
                <input type='time' className='form-control' id='name' placeholder='New Password' />
              </div>
            </div>
            <div className='mb-4 row text-start'>
              <label className='col-form-label col-sm-3' htmlFor='name'>
                End Time
              </label>

              <div className='col-sm-9 my-auto'>
                <input
                  type='time'
                  className='form-control'
                  id='name'
                  placeholder='Confirm New Password'
                />
              </div>
            </div>
            <button
              onClick={handleClose}
              type='button'
              className='btn btn-primary mt-4'
              aria-label='submit data'
            >
              Submit
            </button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder text-white'>Update Status</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={handleClose1}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <form className='text-center m-5'>
            <div className='row mb-4 text-start'>
              <label className='col-sm-3 col-form-label fw-bold fs-6'>
                <span className='required'>Parent</span>
              </label>

              <div className='col-sm-9 fv-row'>
                <select className='form-select form-select-solid form-select-lg fw-bold'>
                  <option value=''>Select Status...</option>
                  <option value='AF'>Active</option>
                  <option value='AX'>Unactive</option>
                </select>
              </div>
            </div>
            <button
              onClick={handleClose}
              type='button'
              className='btn btn-primary mt-4'
              aria-label='submit data'
            >
              Submit
            </button>
          </form>
        </Box>
      </Modal>
    </>
  )
}

export {ViewAppointments}
