import React, {useEffect, useState} from 'react'
import {MDBDataTableV5} from 'mdbreact'
import {MIN} from '../../../../config/constant'

export default function ViewAppointmentsService({services}: any) {
  const initTable = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'disabled',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Price',
        field: 'price',
        width: 200,
      },
      {
        label: 'Duration',
        field: 'duration',
        width: 270,
      },
    ],
    rows: [],
  }
  const [datatable, setDatable] = React.useState()

  const rowItem = (data: any) => {
    return {
      name: data?.service?.name,
      price: '$' + data?.service?.price,
      duration: data.serviceDuration + ' ' + MIN,
    }
  }

  useEffect(() => {
    if (services?.length > 0) {
      get(services)
    }
  }, [services])

  const get = (data: any) => {
    var rows: never[] = []
    data?.forEach((element: any) => {
      rows.push(rowItem(element) as never)
    })

    var table = initTable
    table.rows = rows
    setDatable(table as any)
    return table
  }

  return (
    <>
      <div
        style={{
          overflow: 'auto',
          whiteSpace: 'nowrap',
        }}
      >
        <MDBDataTableV5
          searching={false}
          paging={false}
          responsive
          hover
          order={['age', 'desc']}
          data={datatable}
        />
      </div>
    </>
  )
}
