import client from '../client/client'

const addUsersRequest = async (rData: any) => {
  try {
    return await client.post('api/v1/admin/registration', rData)
  } catch (error) {
    return error
  }
}

const getAllUsersRequest = async () => {
  try {
    return await client.get('api/v1/admin/all-users')
  } catch (error) {
    return error
  }
}

export {addUsersRequest, getAllUsersRequest}
