import InfoWrapper from '../../modules/common/SectionInfo'
import {SubmitHandler, useForm} from 'react-hook-form'
import {SHOW_SUCCESS} from '../../../util'
import {useNavigate} from 'react-router-dom'
import {addUserAction} from '../../reducers/userReducer'
import {useAppDispatch} from '../../store'

interface FormFields {
  name: string
  email: string
  password: string
  password_confirmation: string
}

const CreateUser = () => {
  const {
    register: submit,
    handleSubmit: handleSubmitForm,
    control,
    formState: {errors, defaultValues},
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<FormFields>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
  })

  const navigate = useNavigate()
  const dispatch: any = useAppDispatch()

  const onSubmit: SubmitHandler<FormFields> = (rData: any) => {
    dispatch(addUserAction({data: rData, navigate, SHOW_SUCCESS}))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Create User</h3>
        </div>
      </div>
      <form className='form' onSubmit={handleSubmitForm(onSubmit)}>
        <div className='card-body border-top px-9 pb-9 pt-0'>
          <InfoWrapper>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='col-form-label required fw-bold fs-6'>Name</label>
                <input
                  {...submit('name', {required: 'Name is required'})}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                />
                {errors.name?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.name?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Email</span>
                </label>
                <input
                  type='email'
                  {...submit('email', {required: 'Email is required'})}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                />
                {errors.email?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.email?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Password</span>
                </label>
                <input
                  {...submit('password', {required: 'Password is required'})}
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                />
                {errors.password?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.password?.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Confirm password</span>
                </label>
                <input
                  {...submit('password_confirmation', {
                    required: 'Password confirmation is required',
                    validate: (val: string) => {
                      if (watch('password') != val) {
                        return 'Your passwords do no match'
                      }
                    },
                  })}
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm password'
                />
                {errors.password_confirmation?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.password_confirmation?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfoWrapper>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateUser
