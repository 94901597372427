import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SHOW_ERROR, SHOW_SUCCESS} from '../../../util'
import {RootState} from '../../store'
import {AxiosError} from 'axios'
import {
  copyServicesByParentIdRequest,
  getAllServices,
  updateServiceParentRequest,
  updateServicesRequest,
} from '../../../api/endpoints/service'

// This type describes the error object structure:
export type FetchTodosError = {
  message: string
}

export interface serviceState {
  services_level1: any
  services_level2: any
  services_level3: any
  status: string
  error: string | null

  // currentUser: CurrentUser
}

export interface servicePayload {
  data: any
  success: boolean | null
}

export const initialState: serviceState = {
  services_level1: [],
  services_level2: [],
  services_level3: [],
  status: 'idle',
  error: null,
}

export const fetchServices = createAsyncThunk('services/fetch', async (params: any) => {
  try {
    const res: any = await getAllServices(params)
    const {data, success, message} = res.data

    return {
      params,
      data,
      success,
    }
  } catch (error) {
    console.error(error)
    return error
  }
})

export const updateServicesAction = createAsyncThunk(
  'services/update',
  async (formData: any, thunkAPi: any) => {
    try {
      // let newData = {...formData.data, isAdmin:1}
      const res: any = await updateServicesRequest(formData.data, formData.id)
      const {data, success, message} = res.data
      if (success) {
        // thunkAPi.dispatch(fetchServices())
        SHOW_SUCCESS(true, 'Service updated successfully')
      }

      return {
        formData,
        data,
        success,
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }
)

export const updateServiceParentAction = createAsyncThunk(
  'service/parent/update',
  async (formData: any) => {
    try {
      const res: any = await updateServiceParentRequest(formData.data)
      const {data, success, message} = res.data

      SHOW_SUCCESS(success, 'Service Parent updated successfully')

      return {
        formData,
        data,
        success,
      }
    } catch (error: any) {
      const {message}: AxiosError = error.response.data
      // console.log(error.response.data.message)
      SHOW_ERROR(true, message)
      return error
    }
  }
)

export const copyServicesByParentIdAction = createAsyncThunk(
  'service/parent/copy',
  async (formData: any) => {
    try {
      const res: any = await copyServicesByParentIdRequest(formData.data)
      const {data, success, message} = res.data
      if (success) {
        SHOW_SUCCESS(success, 'Service copied successfully')
        formData.setCopyData({
          parentId: '',
          serviceIds: [],
        })
      }

      return {
        formData,
        data,
        success,
      }
    } catch (error: any) {
      const {message}: AxiosError = error.response.data
      // console.log(error.response.data.message)
      SHOW_ERROR(true, message)
      return error
    }
  }
)

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // When we send a request, // `fetchServices.pending` is being fired:
    builder.addCase(fetchServices.pending, (state) => {
      // At that moment,
      // we change status to `loading`
      // and clear all the previous errors:
      state.status = 'loading'
      state.error = null
    })

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(fetchServices.fulfilled, (state, {payload}) => {
      const {data, success, params}: any = payload
      if (success) {
        if (params?.level === 1) {
          state.services_level1 = data?.filter((ser: any) => {
            const isGeneral = ser?.isGeneral ? ser?.isGeneral === 0 : true
            return isGeneral
          })
        } else if (params?.level === 2) {
          state.services_level2 = data
        } else {
          state.services_level3 = data
        }

        state.status = 'idle'
      }
    })

    // When a server responses with an error:
    builder.addCase(fetchServices.rejected, (state, {payload}) => {
      state.error = payload as string
      state.status = 'idle'
    })

    builder.addCase(updateServicesAction.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateServicesAction.fulfilled, (state, action) => {
      state.status = 'success'
      const {formData, data, success}: any = action.payload
      if (success) {
        if (formData?.level === 1) {
          const index = state.services_level1?.findIndex((ser: any) => ser?.id === formData?.id)
          if (index !== -1) {
            state.services_level1[index] = data
          }
        } else if (formData?.level === 2) {
          const index = state.services_level2?.findIndex((ser: any) => ser?.id === formData?.id)
          if (index !== -1) {
            state.services_level2[index] = data
          }
        } else if (formData?.level === 3) {
          const index = state.services_level3?.findIndex((ser: any) => ser?.id === data?.id)
          console.log(index)

          if (index !== -1) {
            state.services_level3[index] = data
          }
        }
      }
    })
    builder.addCase(updateServicesAction.rejected, (state, action) => {
      state.error = action.payload as string
      state.status = 'idle'
    })
    builder.addCase(updateServiceParentAction.pending, (state) => {
      // state.status = 'loading'
    })
    builder.addCase(updateServiceParentAction.fulfilled, (state, action) => {
      // state.status = 'success'
      const {success, formData}: any = action.payload
      if (success && formData) {
        const index = state.services_level2?.findIndex(
          (ser: any) => ser?.id === formData?.serviceId
        )
        if (index !== -1) {
          state.services_level2.splice(index, 1)
        }
      }
    })
    builder.addCase(updateServiceParentAction.rejected, (state, action) => {
      state.error = action.payload as string
      // state.status = 'idle'
    })
  },
})

// export const {logIn, setLogOut} = serviceSlice.actions
export default serviceSlice.reducer

export const serviceOneSelector = (state: RootState) => state.service.services_level1
export const serviceTwoSelector = (state: RootState) => state.service.services_level2
export const serviceThreeSelector = (state: RootState) => state.service.services_level3
export const statusSelector = (state: RootState) => state.service.status
// export const getIsLoggedIn = (state: RootState) => state?.auth.isLoggedIn
