import client from '../client/client'

const getExplores = async (page: number) => {
  try {
    return await client.get(`api/v1/admin/explores?page=${page}&perPage=10`)
  } catch (error) {
    return error
  }
}

const deleteExplore = async (Id: any) => {
  try {
    return await client.get(`api/v1/admin/explore/delete/${Id}`, {})
  } catch (error) {
    return error
  }
}
export {getExplores, deleteExplore}
