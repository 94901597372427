/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl, KTSVG } from '../../../helpers'

type Props = {
  color?: string
  avatar?: any
  online?: boolean
  name: any
  Category: any
  SubCategory: any
  navigates?: any
  // type: string
  id?: any
  isVerified?: boolean
}

const MembersTeamCard: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  name,
  Category,
  SubCategory,
  navigates,
  isVerified
  // type,
}) => {
  // console.log('navigate and id', navigates + id)

  // const navigate = useNavigate()
  return (
    <div style={{ backgroundColor: '#f5f8fa' }} className={`card ${!isVerified && 'disabled-section'}`}>
      <div className='card-body d-flex flex-center flex-column p-4'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {name.charAt(0)}
              </span>
            ) : (
              <img
                style={{ height: '150px', width: '150px' }}
                alt='Pic'
                src={toAbsoluteUrl(avatar)}
              />
            )}
            {online && (
              <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
            )}
          </div>
        </div>

        <Link to={isVerified && navigates} className='fs-4 text-gray-800 fw-bolder mb-0'>
          {/* text-hover-primary */}
          {name}
        </Link>

        <div
          // onClick={navigate(navigates) as any}
          className='fw-bold text-gray-400 mb-2 badge badge-secondary fs-5'
        >
          {Category}
        </div>
        <div className='fw-bold text-gray-400 mb-6 badge badge-secondary fs-8 mt-0'>
          {SubCategory}
        </div>

        {/* <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'></div>
            <div className='fw-bold text-gray-400'>Avg. Earnings</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'></div>
            <div className='fw-bold text-gray-400'>Total Sales</div>
          </div>
        </div> */}

        {/* <a href='#' className='btn btn-sm btn-light'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          Connect
        </a> */}
      </div>
    </div>
  )
}

export { MembersTeamCard }
