import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {updateProviderPasswordRequest, updateProviderStatusRequest} from '../../../api'
import {RootState} from '../../store'

interface providerState {
  status: string
  error: string | null
}

export const initialState: providerState = {
  status: 'idle',
  error: null,
}

export const updateProviderStatusAction = createAsyncThunk(
  'update/provider-status',
  async (rData: any) => {
    try {
      const res: any = await updateProviderStatusRequest(rData)
      const {data, success} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

export const updateProviderPasswordAction = createAsyncThunk(
  'update/provider-password',
  async (rData: any) => {
    try {
      const res: any = await updateProviderPasswordRequest(rData.data)
      const {data, success} = res.data
      if (success) {
        rData.SHOW_SUCCESS(true, 'Password updated successfully')
        rData.handleClose()
      }
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

const providerSlice = createSlice({
  name: 'Providers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(updateProviderStatusAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateProviderStatusAction.fulfilled, (state, action) => {
      state.status = 'success'
    })
    builder.addCase(updateProviderStatusAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(updateProviderPasswordAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateProviderPasswordAction.fulfilled, (state, action) => {
      state.status = 'success'
    })
    builder.addCase(updateProviderPasswordAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const statusSelector = (state: RootState) => state.providers.status

export default providerSlice.reducer
