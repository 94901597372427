/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { setAuth } from '../core/AuthHelpers'
import { GetProfileRequest, LoginRequest } from '../../../../api/endpoints/auth'
import { GlobalState } from '../../../contexts/AuthContext'
import { ToastContainer, toast } from 'react-toastify'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@admin.com',
  password: '12345678',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const globalState: any = useContext(GlobalState)

  function notify() {
    toast.error("The login detail is incorrect");
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      try {
        const loginModel = {
          grant_type: 'password',
          client_id: 6,
          client_secret: '7tDez9LXTd0cnwQT4SbxReprT9cst7hLxCfGVfih',
          username: values.email,
          password: values.password,
        }

        const res: any = await LoginRequest(loginModel)
        const profile: any = await GetProfileRequest(res?.data?.access_token)
        if (res?.data?.access_token) {
          setAuth({ access_token: res.data.access_token, refresh_token: res.data.access_token });
          globalState.setGlobalState({
            ...globalState,
            isLoggedIn: true,
          })
          if (profile?.data) {
            globalState.setGlobalState({
              ...globalState,
              currentUser: profile?.data?.data
            })
          }
          // dispatch(logIn(res.data))
        } else {
          console.log('error :', JSON.stringify(res.message, null, 2))
          setLoading(false)

          setStatus(res.message)
          setSubmitting(false)
          setLoading(false)
          notify()
        }

        setLoading(false)
      } catch (error) {
        console.log('error :', JSON.stringify(error, null, 2))
        setLoading(false)

        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In to Kazzah</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='text-gray-400 fw-bold fs-4 text-end'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div>
        {/* end::Action */}
      </form>
      <ToastContainer />
    </>
  )
}
