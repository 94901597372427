import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateJson5State} from '../../../reducers/configurationReducer'

const Json5Config = ({state}: any) => {
  const dispatch = useAppDispatch()
  const handleChange = (data: any) => {
    console.log('data', data)

    let finalData = {
      ...state?.value,
      [data?.key]: data?.value ? 1 : 0,
    }
    dispatch(updateJson5State(finalData))
  }
  return (
    <div className='row'>
      <div className='col-lg-6'>
        <div className='row mb-4 text-start'>
          {Object.entries(state?.value).map(([key, value]) => {
            if (key === 'rules') {
              return null // Skip rendering for the "rules" key
            }

            return (
              <>
                <label className='col-lg-4 col-form-label fw-bold fs-6 text-capitalize'>
                  {key}
                </label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-switch fv-row'>
                    <input
                      className={`form-check-input w-45px h-30px`}
                      type={state?.value?.rules?.htmlType} // Adjust the input type based on the key
                      name={key}
                      onChange={(e) => handleChange({key, value: e.target.checked})}
                      checked={Boolean(Number(value))} // Handle checkbox checked state
                      id={key}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Json5Config
