import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {
  getConfigurationByKeyAction,
  singleConfigurationSelector,
  singleConfigurationStatusSelector,
  updateConfigurationAction,
} from '../../../reducers/configurationReducer'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch} from '../../../store'
import Configurations from './Configurations'
import TableLoading from '../../../../_metronic/layout/components/Common/TableSkeletonLoading'
import ConfModal from './modal/ConfModal'

const ViewConfig = () => {
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const {state}: any = location
  const configuration = useSelector(singleConfigurationSelector)
  const singleStatus = useSelector(singleConfigurationStatusSelector)

  useEffect(() => {
    // dispatch(getConfigurationsAction())
    dispatch(getConfigurationByKeyAction(state?.key))
  }, [])

  const handleSubmit = () => {
    dispatch(updateConfigurationAction({data: configuration?.value, id: configuration?.id}))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {singleStatus === 'loading' ? <TableLoading /> : <Configurations state={configuration} />}
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' onClick={() => handleOpen()} className='btn btn-primary'>
            Save
          </button>
          <ConfModal open={open} handleClose={handleClose} handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
}

export {ViewConfig}
