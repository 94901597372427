import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateDisableScheduleDaysState} from '../../../reducers/configurationReducer'

const Json6Config = ({state}: any) => {
  const dispatch = useAppDispatch()
  const handleChange = (data: any) => {
    // console.log('data', data)
    const finalData = {
      ...state?.value,
      [data?.key]: data?.value,
    }
    dispatch(updateDisableScheduleDaysState(finalData))
  }

  const renderInputField = (key: string, value: any) => {
    const isCheckbox = value === 0 || value === 1

    return (
      <div key={key} className='row mb-4 text-start'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>{key}</label>
        <div className='col-lg-8 d-flex align-items-center'>
          {isCheckbox ? (
            <div className='form-check form-switch fv-row'>
              <input
                className={`form-check-input w-45px h-30px`}
                type='checkbox'
                name={key}
                onChange={(e) => handleChange({key, value: e.target.checked ? 1 : 0})}
                checked={value === 1}
                id={key}
              />
            </div>
          ) : (
            <input
              className={`form-control form-control-lg form-control-solid`}
              type='number'
              name={key}
              value={value}
              onChange={(e) => handleChange({key, value: e.target.value})}
              id={key}
            />
          )}
          <label className='form-check-label'></label>
        </div>
      </div>
    )
  }

  return (
    <div>{Object.entries(state?.value).map(([key, value]) => renderInputField(key, value))}</div>
  )
}

export default Json6Config
