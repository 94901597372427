import axios from 'axios'
import {getAuth} from '../../app/modules/auth/core/AuthHelpers'

const client = axios.create({baseURL: 'https://dev-redesign.kazzah.co.uk'})

const token = getAuth()

export const request = ({...options}) => {
  client.defaults.headers.common.Authorization = `Bearer ${token?.access_token}`
  const onSuccess = (response: any) => response
  const onError = (error: any) => error

  return client(options).then(onSuccess).catch(onError)
}
