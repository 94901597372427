const InfoWrapper = ({
  children,
  title,
  bar,
}: {
  children: JSX.Element
  title?: string
  bar?: 'up' | 'down'
}) => {
  return (
    <>
      {bar === 'up' ? <hr /> : null}

      <h4 className='mb-7 fw-bolder'>{title}</h4>
      {children}
      {bar === 'down' ? <hr /> : null}
    </>
  )
}

export default InfoWrapper
