import { Box, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'

const ConfModal = ({ open, handleClose, handleSubmit }: any) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#1e1e2d',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <Typography
                    className='text-center text-white'
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                >
                    Are you sure to save it ?
                </Typography>
                <div className='d-flex justify-content-between mt-4 mx-4'>
                    <div>
                        <button
                            onClick={() => {
                                handleClose()
                                handleSubmit()
                            }}
                            type='button'
                            className='btn btn-success'
                            aria-label='submit data'
                        >
                            Yes
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                handleClose()
                            }}
                            type='button'
                            className='btn btn-danger'
                            aria-label='submit data'
                        >
                            No
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ConfModal