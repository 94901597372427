import React, {useState} from 'react'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import {KTSVG} from '../../../../_metronic/helpers'
import settings from '../../../../config/settings.json'
import {useAppDispatch} from '../../../store'
import {updateMemberPasswordAction} from '../../../reducers/memberReducer'
import {SubmitHandler, useForm} from 'react-hook-form'
import {SHOW_SUCCESS} from '../../../../util'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

type passwordResetModalProps = {
  open: boolean
  handleClose(): void
  userData: any
}

type passwordDataType = {
  password: string
  password_confirmation: string
}

// fix do this
const PasswordResetModal = ({open, handleClose, userData}: passwordResetModalProps) => {
  const dispatch: any = useAppDispatch()

  const {
    register: submit,
    handleSubmit: handleSubmitForm,
    control,
    formState: {errors, defaultValues},
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<passwordDataType>({
    defaultValues: settings.initialState.PWD_RESET,
  })

  const onSubmit: SubmitHandler<passwordDataType> = async (data) => {
    const finalData = {
      ...data,
      id: userData?.id,
    }
    dispatch(updateMemberPasswordAction({data: finalData, handleClose, SHOW_SUCCESS}))
  }

  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])[a-zA-Z0-9!@#%&]{8,}$/

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div className='modal-header'>
          {/* begin::Modal title */}
          <h2 className='fw-bolder text-light'>Update Password</h2>
          {/* end::Modal title */}

          {/* begin::Close */}
          <div
            onClick={handleClose}
            className='btn btn-icon btn-sm btn-active-icon-primary'
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
          {/* end::Close */}
        </div>
        <form onSubmit={handleSubmitForm(onSubmit)} className='text-center m-5'>
          <Typography id='modal-modal-description' sx={{mt: 2}}>
            {/* input field */}

            <div className='mb-3 mt-4 row text-start'>
              <label className='col-form-label col-sm-3 text-light' htmlFor='currentPassword'>
                User
              </label>

              <div className='col-sm-9 my-auto'>
                <input
                  type='text'
                  className='form-control'
                  id='currentPassword'
                  value={`${userData?.firstName} ${userData?.lastName}`}
                  placeholder='User'
                />
              </div>
            </div>
            <div className='mb-3 row text-start'>
              <label className='col-form-label col-sm-3 text-light' htmlFor='newPassword'>
                Password
              </label>

              <div className='col-sm-9 my-auto'>
                <input
                  {...submit('password', {
                    required: 'Password is required',
                    validate: (val: string) => {
                      if (!passwordRegEx.test(val)) {
                        return 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
                      }
                    },
                  })}
                  type='password'
                  className='form-control'
                  id='name'
                  placeholder='New Password'
                />
                {errors.password?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.password?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-3 row text-start'>
              <label className='col-form-label col-sm-3 text-light' htmlFor='confirmPassword'>
                Confirm Password
              </label>

              <div className='col-sm-9 my-auto'>
                <input
                  {...submit('password_confirmation', {
                    required: 'Password confirmation is required',
                    validate: (val: string) => {
                      if (watch('password') != val) {
                        return 'Your passwords do no match'
                      }
                    },
                  })}
                  type='password'
                  className='form-control'
                  id='confirmPassword'
                  placeholder='Confirm New Password'
                />
                {errors.password_confirmation?.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.password_confirmation?.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Typography>
          <button type='submit' className='btn btn-primary mt-4' aria-label='submit data'>
            Submit
          </button>
        </form>
      </Box>
    </Modal>
  )
}

export default PasswordResetModal
