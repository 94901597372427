import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {fetchIconAction} from '../../../reducers/iconReducer'
import {
  copyServicesByParentIdAction,
  fetchServices,
  serviceOneSelector,
  serviceThreeSelector,
  serviceTwoSelector,
  statusSelector,
} from '../../../reducers/servicesReducer'
import {AppDispatch} from '../../../store'
import ServiceCard from '../components/ServiceCard'
import ServicesLayout from '../ServicesLayout'
import {KTSVG} from '../../../../_metronic/helpers'
import ServiceLoading from '../components/ServiceLoading'
import {array_to_CSV} from '../../../../util'

const LEVEL = 3

const ServicesThree = () => {
  const {twoId}: any = useParams()

  const serviceData = useSelector(serviceThreeSelector)
  const level2ServiceData = useSelector(serviceTwoSelector)
  const level1ServiceData = useSelector(serviceOneSelector)
  const status = useSelector(statusSelector)
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(fetchServices({level: 3, parentId: twoId}))

    dispatch(fetchIconAction())
  }, [])

  const [sort, setSort] = useState(0)

  const [admin, setAdmin] = React.useState(true)
  const [notAdmin, setNotAdmin] = React.useState(false)
  const [both, setBoth] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [isCopy, setIsCopy] = useState(false)
  const [copyData, setCopyData] = useState<{
    parentId: number | string
    serviceIds: Array<any>
  }>({
    parentId: '',
    serviceIds: [],
  })
  const isActiveAdmin = [...serviceData].filter((f) => f.isAdmin === 1 && f.isActive)
  const isInActiveAdmin = [...serviceData].filter((f) => f.isAdmin === 1 && !f.isActive)
  const isActivePro = [...serviceData].filter((f) => f.isAdmin !== 1 && f.isActive)
  const isInActivePro = [...serviceData].filter((f) => f.isAdmin !== 1 && !f.isActive)
  const isActiveBoth = [...serviceData].filter((f) => f.isActive)
  const isInActiveBoth = [...serviceData].filter((f) => !f.isActive)

  const activeServiceLength = checkFilter()?.filter((ser: any) => ser?.parentId == twoId)
  const toggleSort = () => {
    setSort((prevSort) => (prevSort + 1) % 3)
  }

  const sortData = (data: any[], sortType: string) => {
    return data.sort((a, b) => a?.name.localeCompare(b?.name) * (sortType === 'asc' ? 1 : -1))
  }

  function checkFilter() {
    if (admin && isActive) {
      return isActiveAdmin
    } else if (admin && !isActive) {
      return isInActiveAdmin
    } else if (notAdmin && isActive) {
      return isActivePro
    } else if (notAdmin && !isActive) {
      return isInActivePro
    } else if (both && isActive) {
      return isActiveBoth
    } else if (both && !isActive) {
      return isInActiveBoth
    } else {
      return serviceData
    }
  }
  const SortedServiceData = useMemo(() => {
    let data

    if (admin || notAdmin || both || isActive) {
      data = checkFilter()
    } else {
      data = serviceData
    }

    switch (sort) {
      case 1:
        data = sortData([...data], 'asc')
        break
      case 2:
        data = sortData([...data], 'desc')
        break
      default:
        data = data
    }
    return data
  }, [sort, serviceData, admin, notAdmin, both, isActive])

  const handleCopySubmit = () => {
    const finalData = {
      ...copyData,
      serviceIds: array_to_CSV(copyData.serviceIds),
    }
    setIsCopy(false)
    dispatch(copyServicesByParentIdAction({data: finalData, setCopyData: setCopyData}))
  }

  const handleServicesChange = (data: any) => {
    setCopyData((prevCopyData) => {
      const copyDataCopy = {
        ...prevCopyData,
        parentId: data.parentId,
      }

      const isPresent = copyDataCopy?.serviceIds?.includes(data.serviceIds)
      const isArray = Array.isArray(data.serviceIds)
      // console.log('array', isArray)

      if (!isPresent) {
        // Add to the array if not already present
        if (!isArray) {
          copyDataCopy.serviceIds = [...copyDataCopy.serviceIds, data.serviceIds]
        }
      } else {
        // Remove the item if it already exists
        copyDataCopy.serviceIds = copyDataCopy.serviceIds.filter(
          (id: string | number) => id !== data.serviceIds
        )
      }

      // console.log('copydata', copyDataCopy)
      return copyDataCopy
    })
  }

  return (
    <ServicesLayout>
      {/* begin::Menu */}
      <div className='d-flex justify-content-end mt-n8 mb-3 dropdown'>
        {!isCopy && (
          <button
            className='btn btn-sm btn-color-white btn-active-color-white border-0 bg-primary me-2'
            onClick={() => setIsCopy(true)}
          >
            Copy services
          </button>
        )}
        <div
          onClick={toggleSort}
          className={`btn btn-sm btn-icon btn-color-white border-0 me-2 ${
            sort ? 'bg-primary' : 'bg-secondary'
          }`}
        >
          <img
            style={{filter: 'brightness(200 %) saturate(0%)'}}
            src={`${sort == 2 ? '/media/ztoa.png' : '/media/atoz.png'}`}
            alt='icon'
            className='my-1'
          />
        </div>
        <div
          style={{cursor: 'auto'}}
          className='btn btn-sm btn-icon btn-color-white border-0 me-2 bg-primary'
        >
          {activeServiceLength?.length}
        </div>
        {/* <h2 className='my-auto badge badge-primary me-3'>{activeServiceLength?.length}</h2> */}
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-white btn-active-color-white border-0 me-n3 bg-primary'
          id='dropdownMenuButton2'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
        </button>
        <ul
          className='dropdown-menu dropdown-menu-light w-250px w-md-300px'
          aria-labelledby='dropdownMenuButton2'
        >
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-7 pt-5'>
            <div className='mb-10'>
              <label className='form-label fw-bold mb-4'>Services created by:</label>

              <div className='d-flex'>
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    onChange={(e) => {
                      setNotAdmin(true)
                      setAdmin(false)
                      setBoth(false)
                    }}
                    checked={notAdmin ? true : false}
                  />
                  <span className='form-check-label'>Pro</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    onChange={() => {
                      setAdmin(true)
                      setNotAdmin(false)
                      setBoth(false)
                    }}
                    checked={admin ? true : false}
                  />
                  <span className='form-check-label'>Admin</span>
                </label>
                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    onChange={() => {
                      setBoth(true)
                      setAdmin(false)
                      setNotAdmin(false)
                    }}
                    checked={both ? true : false}
                  />
                  <span className='form-check-label'>Both</span>
                </label>
              </div>
              <label className='form-label fw-bold mt-6'>Services type:</label>

              <div className='d-flex'>
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    onChange={(e) => {
                      setIsActive(true)
                    }}
                    checked={isActive ? true : false}
                  />
                  <span className='form-check-label'>Active</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    onChange={(e) => {
                      setIsActive(false)
                    }}
                    checked={!isActive ? true : false}
                  />
                  <span className='form-check-label'>InActive</span>
                </label>
              </div>
            </div>
          </div>
        </ul>
      </div>
      {/* end::Menu */}
      {isCopy ? (
        <div className='d-flex justify-content-between gap-2 mb-2'>
          <div className='d-flex gap-2 w-100'>
            <select
              name=''
              className='form-select'
              id=''
              onChange={(e) => {
                dispatch(fetchServices({level: 2, parentId: e.target.value}))
              }}
            >
              <option value=''>Select channel</option>
              {level1ServiceData?.map((service: any) => (
                <option value={service?.id}>{service?.name}</option>
              ))}
            </select>
            <select
              name=''
              className='form-select'
              id=''
              onChange={(e) => {
                handleServicesChange({
                  ...copyData,
                  parentId: e.target.value,
                })
              }}
            >
              <option value=''>Select sub channel</option>
              {level2ServiceData
                ?.filter((service: any) => {
                  if (service?.id == twoId) {
                    return null
                  } else {
                    return service
                  }
                })
                ?.map((service: any) => (
                  <option value={service?.id}>{service?.name}</option>
                ))}
            </select>
          </div>
          <div className='d-flex'>
            <button className='btn btn-primary' onClick={handleCopySubmit}>
              Copy
            </button>
            <button className='btn btn-danger ms-1' onClick={() => setIsCopy(false)}>
              Cancel
            </button>
          </div>
        </div>
      ) : null}
      <div className='row'>
        {status === 'loading' ? (
          <ServiceLoading />
        ) : (
          SortedServiceData?.filter((ser: any) => ser?.parentId == twoId)?.map(
            (ser: any, idx: number) => (
              <ServiceCard
                key={idx}
                data={ser}
                copy={isCopy}
                handleServicesChange={handleServicesChange}
                cData={copyData}
              />
            )
          )
        )}
        {/* {serviceData
          ?.filter((ser: any) => ser?.parentId == twoId)
          ?.map((ser: any, idx: number) => (
            <ServiceCard key={idx} data={ser} />
          ))} */}
      </div>
    </ServicesLayout>
  )
}

export default ServicesThree
