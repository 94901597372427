import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {updateMemberPasswordRequest, updateMemberStatusRequest} from '../../../api/endpoints/member'

interface memberState {
  status: string
  error: string | null
}

export const initialState: memberState = {
  status: 'idle',
  error: null,
}

export const updateMemberStatusAction = createAsyncThunk(
  'update/member-status',
  async (rData: any) => {
    try {
      const res: any = await updateMemberStatusRequest(rData)
      const {data, success} = res.data
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

export const updateMemberPasswordAction = createAsyncThunk(
  'update/member-password',
  async (rData: any) => {
    try {
      const res: any = await updateMemberPasswordRequest(rData.data)
      const {data, success} = res.data
      if (success) {
        rData.SHOW_SUCCESS(true, 'Password updated successfully')
        rData.handleClose()
      }
      return {
        data,
        success,
        code: 200,
      }
    } catch (error) {
      return error
    }
  }
)

const memberSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(updateMemberStatusAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateMemberStatusAction.fulfilled, (state, action) => {
      state.status = 'success'
    })
    builder.addCase(updateMemberStatusAction.rejected, (state, action) => {
      state.status = 'failed'
    })

    builder.addCase(updateMemberPasswordAction.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateMemberPasswordAction.fulfilled, (state, action) => {
      state.status = 'success'
    })
    builder.addCase(updateMemberPasswordAction.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export default memberSlice.reducer
