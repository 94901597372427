import React from 'react'
import {useAppDispatch} from '../../../store'
import {updateJson2State} from '../../../reducers/configurationReducer'
import {snake_to_title} from '../../../../util/helper'

const Json2Config = ({state}: any) => {
  const dispatch = useAppDispatch()

  const handleChange = ({value, key, data}: {value: string; key: string; data: any}) => {
    // Clone the current state to avoid mutation
    // console.log('data', data)
    let finalData

    const newState = {...state}

    // Extract key and paymentMethods from data
    const [dataKey, paymentMethods] = data

    // Check if the key is within the payment_methods
    // if (dataKey === 'payment_methods' && paymentMethods) {
    // Create a new object to avoid mutating the frozen object
    const newPaymentMethods = {...paymentMethods}

    // Set all values to '0' except the selected one
    Object.keys(newPaymentMethods).forEach((k) => {
      newPaymentMethods[k] = k === key.split('.').pop() ? value : '0'
    })

    // Update the payment_methods in the newState
    newState[dataKey] = newPaymentMethods
    finalData = {
      methods: newPaymentMethods,
      key: dataKey,
    }

    // Update the state
    // console.log(finalData)
    dispatch(updateJson2State(finalData))
  }

  //   console.log('data', state)

  return (
    <div className='row'>
      {state?.value &&
        Object.entries(state?.value).map(
          ([mainKey, mainValue]: any) =>
            mainKey !== 'rules' && (
              <>
                <h6 className='mt-2'>{snake_to_title(mainKey)}</h6>
                {Object.entries(mainValue).map(([nestedKey, nestedValue]) => (
                  <div className='col-lg-6 text-capitalize my-2' key={`${mainKey}-${nestedKey}`}>
                    <input
                      className={`form-check-input`}
                      type={state?.value?.rules?.htmlType}
                      name={`${mainKey}.${nestedKey}`}
                      onChange={(e) => {
                        handleChange({
                          value: e.target.checked ? '1' : '0',
                          key: `${mainKey}.${nestedKey}`, // Dynamic key
                          data: [mainKey, mainValue],
                        })
                      }}
                      checked={Boolean(Number(nestedValue))}
                      id={`${mainKey}-${nestedKey}`}
                    />
                    <label className='form-check-label ms-1'>{nestedKey}</label>
                  </div>
                ))}
              </>
            )
        )}
    </div>
  )
}

export default Json2Config

// const handleChange = ({value, key, data}: {value: string; key: string; data: any}) => {
//     // Clone the current state to avoid mutation
//     const newState = {...state}

//     // Extract key and paymentMethods from data
//     const [dataKey, paymentMethods] = data

//     // Check if the key is within the payment_methods
//     if (dataKey === 'payment_methods' && paymentMethods) {
//       // Create a new object to avoid mutating the frozen object
//       const newPaymentMethods = {...paymentMethods}

//       // Set all values to '0' except the selected one
//       Object.keys(newPaymentMethods).forEach((k) => {
//         newPaymentMethods[k] = k === key.split('.').pop() ? value : '0'
//       })

//       // Update the payment_methods in the newState
//       newState[dataKey] = newPaymentMethods
//     } else {
//       // If it's not within payment_methods, update the value directly
//       const keys = key.split('.')
//       let currentLevel = newState
//       keys.forEach((currentKey, index) => {
//         if (index === keys.length - 1) {
//           currentLevel[currentKey] = value
//         } else {
//           currentLevel[currentKey] = currentLevel[currentKey] || {}
//           currentLevel = currentLevel[currentKey]
//         }
//       })
//     }

//     // Update the state
//     console.log(newState)
//   }
