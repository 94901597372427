import client from '../client/client'

const GetMembersListRequest = async () => {
  try {
    const {data} = await client.get('api/v1/admin/member/listing?perPage=100&sort=id|asc')
    return data.data
  } catch (error) {
    return error
  }
}

const GetMembersProfileRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/member/${id}`)
    return data.data
  } catch (error) {
    return error
  }
}

const GetMembersTeamRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/member/team/${id}`, {})
    return data.data
  } catch (error) {
    return error
  }
}

const GetMembersAppointmentsRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/member/appointments/${id}`)
    return data.data
  } catch (error) {
    return error
  }
}

const getMembersConnectionRequest = async (id: any) => {
  try {
    const {data} = await client.get(`api/v1/admin/member/connections/${id}`, {})
    return data.data
  } catch (error) {
    return error
  }
}

const deleteMembers = async (id: any) => {
  try {
    return await client.delete(`api/v1/admin/member/delete/${id}`, {})
  } catch (error) {
    return error
  }
}

const updateMemberStatusRequest = async (data: any) => {
  try {
    return await client.put(`api/v1/admin/member/update-status`, data)
  } catch (error) {
    return error
  }
}

const updateMemberPasswordRequest = async (data: any) => {
  try {
    return await client.post(`api/v1/admin/member/reset-password`, data)
  } catch (error) {
    return error
  }
}

export {
  GetMembersListRequest,
  GetMembersProfileRequest,
  getMembersConnectionRequest,
  GetMembersTeamRequest,
  GetMembersAppointmentsRequest,
  deleteMembers,
  updateMemberStatusRequest,
  updateMemberPasswordRequest,
}
