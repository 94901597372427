/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, createContext, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useNavigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {getAuth, removeAuth} from '../modules/auth/core/AuthHelpers'
import {GlobalState} from '../contexts/AuthContext'
import jwt_decode, { JwtPayload } from "jwt-decode";
import {SHOW_ERROR} from '../../util'

const AppRoutes: FC = () => {
  const [globalState, setGlobalState] = useState({
    isLoggedIn: false,
    currentUser: {},
  })
  const auth = getAuth()
  // const navigate = useNavigate()

  function isJwtExpire(TOKEN: string) {
    const decoded: any = jwt_decode(TOKEN)
    const tokenExpiration = decoded.exp * 1000 // convert seconds to milliseconds
    let currentDate = new Date()

    // JWT exp is in seconds
    if (tokenExpiration < currentDate.getTime()) {
      // if (tokenExpiration <= Date.now()) {
      SHOW_ERROR(true, 'Session expired, please login again')
      removeAuth()
      // navigate('auth/login')
      return
    } else {
      // console.log("not expire ");
    }
  }

  useEffect(() => {
    if (auth) {
      isJwtExpire(auth?.access_token)
    }
    return () => {}
  }, [globalState.isLoggedIn])

  return (
    <GlobalState.Provider value={{globalState, setGlobalState}}>
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {globalState.isLoggedIn || auth?.access_token ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </GlobalState.Provider>
  )
}

export {AppRoutes}
