import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import "../src/_metronic/assets/css/style.css"
import store from './app/store'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
*
* import './_metronic/assets/css/style.rtl.css'
**/

// Assets
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import 'react-toastify/dist/ReactToastify.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'


// Chart.register(...registerables)


const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        notifyOnChangeProps: 'tracked',
      },
    },
  }
)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <AppRoutes />
          <ToastContainer />
        </Provider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer />
    </QueryClientProvider>
  )
}
