import client from '../client/client'

const GetAppointmentsListRequest = async () => {
  try {
    const {data} = await client.get('api/v1/admin/member/appointments?perPage=100&sort=id|asc', {})
    return data.data
  } catch (error) {
    return error
  }
}

const fetchSingleAppointmentRequest = async (Id: number | string) => {
  try {
    const {data} = await client.get(`api/v1/admin/appointment/${Id}`, {})
    return data.data
  } catch (error) {
    return error
  }
}
const deleteAppointments = async (Id: any) => {
  try {
    return await client.delete(`api/v1/admin/appointment/delete/${Id}`)
  } catch (error) {
    return error
  }
}

export {GetAppointmentsListRequest, fetchSingleAppointmentRequest, deleteAppointments}
