import React, { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useSelector } from 'react-redux'
import { iconListSelector } from '../../../reducers/iconReducer'

const IconsLibrary = ({ open, setOpen, setLibraryIcon }: any) => {

    const icons = useSelector(iconListSelector)
    const [search, setSearch] = useState<any>('')
    const handleChange = (e: any) => {
        if (e.target.value === '') {
            setSearch(null)
        } else {
            // setSearching(true);
            setSearch(e.target.value.toUpperCase())
        }
        // console.log(search);
    }
    return (
        <>
            <div className='modal-header mb-2'>
                <h2 className='fw-bolder text-white'>Icons</h2>
                <div
                    onClick={() => setOpen({ ...open, iconModel: false })}
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    style={{ cursor: 'pointer' }}
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
                {/* end::Close */}
            </div>
            <div className='card-title text-center'>
                {/* begin::Search */}
                <div className='d-flex align-items-center my-3 m-auto'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 position-absolute ms-6'
                    />
                    <input
                        onChange={handleChange}
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search icons'
                    />
                </div>
                {/* end::Search */}
            </div>
            <div className='row mt-4'>
                {search
                    ? icons
                        ?.filter((f) => f?.name?.toUpperCase().includes(search))
                        ?.map((icon: any, index: any) => (
                            <div className='col-3 rounded text-center my-1' key={index}>
                                <div
                                    style={{ width: '100px', height: '50px' }}
                                    className='symbol symbol-35px bg-white text-center bg-white align-items-center pointer'
                                    onClick={() => {
                                        setLibraryIcon(icon.icon)
                                        setOpen({ ...open, iconModel: false })
                                    }}
                                    data-bs-toggle='tooltip'
                                    title='Ana Stone'
                                >
                                    <p className='fw-bold fs-4 text-primary mb-0 mt-4'>
                                        <img src={icon.icon} alt='icon' width={30} height={30} />
                                    </p>
                                </div>
                                <span className='fs-7 text-white'>{icon.name}</span>
                            </div>
                        ))
                    : icons?.map((icon: any, index: any) => (
                        <div className='col-3 rounded text-center my-1' key={index}>
                            <div
                                style={{ width: '100px', height: '50px' }}
                                className='symbol symbol-35px bg-white text-center bg-white align-items-center pointer'
                                onClick={() => {
                                    setLibraryIcon(icon.icon)
                                    setOpen({ ...open, iconModel: false })
                                }}
                                data-bs-toggle='tooltip'
                                title='Ana Stone'
                            >
                                <p className='fw-bold fs-4 text-primary mb-0 mt-4'>
                                    <img src={icon.icon} alt='icon' width={30} height={30} />
                                </p>
                            </div>
                            <span className='fs-7 text-white'>{icon.name}</span>
                        </div>
                    ))}
            </div>
        </>
    )
}

export default IconsLibrary